<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import {
    changeType,
    checkAppState,
    convertFun,
    clickConvert,
    removeSingleFile
  } from "../convert";
  import converting from "../../common/converting.vue";
  import { openPop } from "../../../api/common";

  import "../convert.scss";

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "pdf",
        reqApi: "/app/txtConv",
        fileName: this.fileList[0].name,
        conuploadState: this.uploadState,
        clickCovertFlag: false,
      };
    },
    props: ["fileList", "uploadState"],
    components: {
      // pdf,
      converting,
    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      clickConvert,
      removeSingleFile
    },
    watch: {
      fileList: {
        handler(val) {
          if (val[0]) {
            this.fileName = val[0].name;
          }
        },
        deep: true, //true 深度监听
      },
      uploadState: {
        handler(val) {
          this.conuploadState = val;
          val == 1 && this.clickCovertFlag ? this.convertFun(true) : "";
          val == 0 && this.clickCovertFlag ? openPop(this, 5) : "";
        },
        deep: true, //true 深度监听
      },
      clickCovertFlag: {
        handler: function (val) {
          this.conuploadState == 1 && val ? this.convertFun(true) : "";
          this.conuploadState == 0 && val ? openPop(this, 5) : "";
        },
      },
    },
    mounted() {
      if (this.checkAppState() == 'operate') {
        this.convertFun(true)
      }

    },
  };
</script>
<style lang="scss" scoped>
  .action-area-wrap {
    display: flex;
    align-items: flex-end;
  }

  .pre-pdf-wrap .pre-pdf-content {
    box-shadow: none;
    border-radius: 0;
    background: none;
  }

  .pre-pdf-content:after {
    display: none !important;

  }

  .file-name-wrap {
    display: flex;
    margin-top: 1rem;
    max-width: 150px;
    margin: 0 auto;
    justify-content: center;

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }
  }
</style>