<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import {
    changeType,
    checkAppState,
    convertFun,
    removeSingleFile,
  } from "../convert";
  import converting from "../../../common/converting.vue";

  import "../convert.scss";

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "pdf",
        reqApi: "/app/wordConv",
        fileName: this.fileList[0].name,
      };
    },
    props: ["fileList", "downUrl"],
    components: {
      // pdf,
      converting,
    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      removeSingleFile,
    },
    watch: {

    },
    mounted() {

    },

  };
</script>
<style lang="scss" scoped>
  .action-area-wrap {
    display: flex;
    align-items: flex-end;
  }

  .pre-pdf-wrap .pre-pdf-content {
    box-shadow: none;
    border-radius: 0;
    background: none;
  }

  .pre-pdf-content:after {
    display: none !important;

  }

  .fun-area-wrap .pre-icon-wrap .hover-tit {
    top: calc(100% - 56px);
  }

  .file-name-wrap {
    display: flex;
    margin-top: 1rem;
    max-width: 150px;
    margin: 0 auto;

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }
  }
</style>