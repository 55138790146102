<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import converting from "../../common/converting.vue";
  import { backToChooseType } from "../convert";

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        gaStartime: "",
      };
    },
    props: ["fileList", "downFileType"],

    components: {
      // pdf,
      converting,
    },
    methods: {
      /*  convertFun() {
        this.pdfUrl =
          "https://appsrv.passfab.com/static/1619512510mim/download/8f608b20751df15c821969f8318f9953.pdf";
        this.handlePdfSize();
      }, */
      backToChooseType,
      checkAppState() {
        return this.$store.state.appState;
      },
      handlePdfSize() {
        document.querySelectorAll(".canvasWrapper canvas").forEach((index) => {
          ////console.log(index.style.width.replace("px", ""));
          ////console.log(index.style.height.replace("px", ""));
          index.style.width = "120px";
          index.style.height = "150px";
        });
        document.querySelectorAll(".page").forEach((index) => {
          index.style.width = "120px";
          index.style.height = "150px";
        });
      },
      convertFun(firstFlag) {
        firstFlag ? firstFlag : "";
        const _this = this;
        this.gaStartime = new Date().getTime();
        this.canConverFlag = true;
        this.$store.commit("changeAppState", "converting");
        // //console.log("jpg:", this.$parent.fileList[0]);
        this.$http
          .post("/app/pdfConv", {
            token: this.$store.state.pdfToken,
            file_url: this.$parent.fileList[0].fileUrl,
            conv: "jpg",
          })
          .then((response) => {
            ////console.log(response);
            if (response.code == 200) {
              this.convertNumber = response.data.result[0].number;
              _this.$http
                .ws(_this.$store.state.pdfToken, _this.convertNumber)
                .then((response) => {
                  let imgFileList = response.data.output_files;
                  let formData = new FormData();
                  formData.append("token", _this.$store.state.pdfToken);
                  formData.append("task_id", _this.$parent.taskId);
                  for (let i = 0; i < imgFileList.length; i++) {
                    formData.append("file_url[]", imgFileList[i]);
                  }
                  _this.$http
                    .post("/app/compressFile", formData)
                    .then((Response) => {
                      ////console.log("_this.$store.state.appState:", _this.$store.state.appState);
                      if (Response.code == 200 && _this.$store.state.appState == 'converting') {
                        _this.downUrl = Response.data.result;
                        this.$emit("update:downUrl", _this.downUrl);
                        this.$store.commit("changeAppState", "converted");
                        let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                        _this.$gtag.event("process_success", {
                          event_category: _this.$parent.pageName,
                          event_label: `${covertTime}-${(this.$parent.fileList[0].size / 1024 / 1024).toFixed(3)}`,
                        });
                        _this.$gtag.event("process_success", {
                          event_category: _this.$parent.pageName,
                          event_label: "1",
                        });
                        _this.$gtag.event("process_file", {
                          event_category: _this.$parent.pageName,
                          event_label: "success",
                        });
                      } else {
                        let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                        _this.$gtag.event("process_fail", {
                          event_category: _this.$parent.pageName,
                          event_label: covertTime,
                        });
                        _this.$gtag.event("process_fail", {
                          event_category: _this.$parent.pageName,
                          event_label: "compress_service_error",
                        });
                        _this.$gtag.event("process_file", {
                          event_category: _this.pageName,
                          event_label: "fail",
                        });
                        _this.$message({
                          showClose: true,
                          duration: 8000,
                          type: "error",
                          message: "The network is abnormal. Please check the network connection.",
                        });
                        _this.$store.commit("changeAppState", "failConvert");
                        // let converTime =
                        //   Math.floor(
                        //     ((new Date().getTime() - _this.gaStartime) / 1000) *
                        //     100
                        //   ) / 100;
                      }
                    })
                    .catch((response) => {
                      let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                      _this.$gtag.event("process_fail", {
                        event_category: _this.$parent.pageName,
                        event_label: covertTime,
                      });
                      _this.$gtag.event("process_fail", {
                        event_category: _this.$parent.pageName,
                        event_label: "ws_service_error",
                      });
                      _this.$gtag.event("process_file", {
                        event_category: _this.pageName,
                        event_label: "fail",
                      });
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      });
                      _this.$store.commit("changeAppState", "failConvert");
                    });
                })
                .catch((response) => {
                  let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                  _this.$gtag.event("process_fail", {
                    event_category: _this.$parent.pageName,
                    event_label: covertTime,
                  });
                  _this.$gtag.event("process_fail", {
                    event_category: _this.$parent.pageName,
                    event_label: "ws_service_error",
                  });
                  _this.$gtag.event("process_file", {
                    event_category: _this.pageName,
                    event_label: "fail",
                  });
                  _this.$message({
                    showClose: true,
                    duration: 8000,
                    type: "error",
                    message: "The network is abnormal. Please check the network connection.",
                  });
                  _this.$store.commit("changeAppState", "failConvert");
                  // _this.openConverWrong();
                });
            } else {
              // _this.openConverWrong();
              let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
              _this.$gtag.event("process_fail", {
                event_category: _this.$parent.pageName,
                event_label: covertTime,
              });
              _this.$gtag.event("process_file", {
                event_category: _this.pageName,
                event_label: "fail",
              });
              _this.$gtag.event("process_fail", {
                event_category: _this.$parent.pageName,
                event_label: "service_error",
              });
              _this.$message({
                showClose: true,
                duration: 8000,
                type: "error",
                message: "The network is abnormal. Please check the network connection.",
              });
              _this.$store.commit("changeAppState", "failConvert");

            }
          })
          .catch((response) => {
            ////console.log(response);
            _this.openConverWrong();
            let converTime =
              Math.floor(
                ((new Date().getTime() - _this.gaStartime) / 1000) * 100
              ) / 100;
          });
      },
      openConverWrong() { },
    },
    mounted() {
      console.log();
      this.$emit("update:downFileType", "zip");
      if (this.checkAppState() == 'operate') {
        this.convertFun(true)
      }
    },
  };
</script>
<style lang="scss" scoped></style>