<template lang="">
  <div id="uploading">
    <p class="uploading-firstP">{{$t(`message.UploadFun.title`)}}</p>
    <p class="uploading-secondP">{{convertingText}}</p>
    <img class='uploading' src="../../assets/convert/uploading.gif" alt="">
  </div>

</template>
<script>

  export default {
    data() {
      return {
        convertingText: '',
        timer: null
      }
    },
    watch: {},
    mounted() {
      this.convertingText = this.$t(`message.UploadFun.uploading[0]`)
      let _this = this
      ////console.log("_this:", _this);

      this.timer = setInterval(() => {
        if (_this.convertingText == _this.$t(`message.UploadFun.uploading[0]`)) {
          _this.convertingText = _this.$t(`message.UploadFun.uploading[1]`)
        } else {
          _this.convertingText = _this.$t(`message.UploadFun.uploading[0]`)
        }
      }, 3000)
    },
    unmounted() {
      clearInterval(this.timer)
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
  @keyframes rotate {
    from {
      transform: rotate(-90deg);
    }

    to {
      transform: rotate(270deg);
    }
  }

  #uploading img.uploading {
    width: 180px;
    /* animation: rotate 2s cubic-bezier(.95, .46, .43, .8) infinite; */
  }

  #uploading {
    background: #ffffff;
    border-radius: 16px;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 0 0;

    .uploading-firstP {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #1D1D1F;
    }

    .uploading-secondP {
      margin: 10px 0 40px 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: rgba(29, 29, 31, 0.7);
    }

  }
</style>