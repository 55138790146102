<template lang="">
  <!-- 中间放文件 -->
  <div class="fun-area-wrap" :uploadingFlag="$store.state.uploadingFlag"
    v-if="(checkAppState() == 'start' ||checkAppState() == 'operate') &&  pageId != 13">
    <div @click="changeClickOrDrag()" style="height: 548px;">
      <el-upload v-if="!$store.state.uploadingFlag" class="upload-demo upload-area dotteBox" drag ref="upload" action=""
        multiple :auto-upload="false" :before-upload="beforeUpload" :show-file-list="false" :on-change="clickUpload"
        :file-list="fileList" :disabled="$store.state.uploadingFlag" :accept="fileTypeUpload">
        <div class="flexBox" v-for="(item,index) in list" :key="index">
          <div v-show="pageId == item.id" style="display:flex;flex-direction: column;align-items: center;">
            <img class="ptw" :src="item.src" alt="">
            <p
              style="font-family: 'Inter';font-style: normal;font-size:32px;font-weight:bold;margin:40px 0 8px 0px;line-height: 44px;">
              {{item.text}}</p>
            <p
              style="font-family: 'Inter'; font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{item.p}}</p>
            <p
              style="font-family: 'Inter';font-style: normal;margin:64px 0 8px 0;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.selectFile[3]`)}}
            </p>
            <div class="selectButton cursor">{{$t(`message.selectFile[1]`)}}</div>
            <div style="margin-top:32px">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="margin-top:-3px ;">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.65811 0.0256583C6.55548 -0.00855278 6.44452 -0.00855278 6.34189 0.0256583L0.341886 2.02566C0.137715 2.09372 0 2.28478 0 2.5V9.5C0 9.60554 0.0333988 9.70838 0.0954135 9.79379C0.286104 10.0564 0.470108 10.3171 0.651201 10.5737L0.651239 10.5737C1.27002 11.4504 1.85483 12.2789 2.5563 12.9713C3.49179 13.8947 4.64103 14.5911 6.38941 14.9876C6.46157 15.004 6.53646 15.0041 6.60868 14.988C9.8687 14.2621 11.3239 12.288 12.9222 9.7678C12.973 9.68771 13 9.59483 13 9.5V2.5C13 2.28478 12.8623 2.09372 12.6581 2.02566L6.65811 0.0256583ZM1 9.33811V2.86038L6.5 1.02705L12 2.86038V9.35452C10.479 11.7411 9.22029 13.3468 6.50088 13.9869C4.99483 13.6278 4.04041 13.0311 3.25878 12.2596C2.62646 11.6355 2.11047 10.9051 1.50375 10.0462L1.50369 10.0461C1.3434 9.81922 1.17677 9.58335 1 9.33811ZM9.89468 5.80696C10.0642 5.58899 10.0249 5.27485 9.80697 5.10532C9.589 4.93578 9.27486 4.97505 9.10532 5.19302L5.95305 9.24594L4.35355 7.64644C4.15829 7.45118 3.84171 7.45118 3.64645 7.64644C3.45118 7.8417 3.45118 8.15828 3.64645 8.35355L5.64645 10.3535C5.74786 10.455 5.88793 10.5079 6.03107 10.499C6.17421 10.4901 6.30663 10.4202 6.39468 10.307L9.89468 5.80696Z"
                  fill="#BBBBBC" />
              </svg>
              <span
                style="color: rgba(29, 29, 31, 0.3);margin-left:8px;font-family: 'Inter';font-style: normal;font-size: 16px;color: rgba(29, 29, 31, 0.3);">{{$t(`message.selectText[1]`)}}</span>
            </div>
          </div>
        </div>
      </el-upload>
      <!-- <uploading /> -->

      <div v-if="$store.state.uploadingFlag" style="height:100%">
        <uploading />
      </div>
    </div>
  </div>
  <pdfToWord class="fun-area-wrap" v-if="currentFun == 'pdfToWord' && pageId == 6" :fileList="fileList"
    :totalFileCount="totalFileCount" v-model:downUrl.sync="downInof.downUrl"
    v-model:downFileType.sync="downInof.downFileType" v-model:currentFun.sync="currentFun"
    v-model:uploadState.sync="uploadState" @checkFileType="checkFileType" />
  <pdfToImg class="fun-area-wrap" v-if="currentFun == 'pdfToImg' && pageId == 7 " :fileList="fileList"
    v-model:firstScreen.sync="firstScreen" v-model:downUrl.sync="downInof.downUrl" v-model:taskId.sync="taskId"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun" @checkFileType="checkFileType"
    @removeFile="removeFileFun" />
  <pdfToExcel class="fun-area-wrap" v-if="currentFun == 'pdfToExcel' && pageId == 8 " :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @checkFileType="checkFileType" />
  <pdfToTexT class="fun-area-wrap" v-if="currentFun == 'pdfToText' && pageId == 10" :fileList="fileList"
    :uploadState="uploadState" v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" @checkFileType="checkFileType" />
  <pdfToPPT class="fun-area-wrap" v-if="currentFun == 'pdfToPPT' && pageId == 9 " :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @checkFileType="checkFileType" />
  <pdfToHtml class="fun-area-wrap" v-if="currentFun == 'pdfToHtml' && pageId == 11" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState" v-model:downUrl.sync="downInof.downUrl"
    @checkFileType="checkFileType" />

  <wordToPdf class="fun-area-wrap" v-if="currentFun == 'wordToPdf' && pageId == 12" :fileList="fileList"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downUrl.sync="downInof.downUrl" />
  <textToPdf class="fun-area-wrap" v-if="currentFun == 'textToPdf' && pageId == 16" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" />
  <pptToPdf class="fun-area-wrap" v-if="currentFun == 'pptToPdf' && pageId == 15" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" />
  <excelToPdf class="fun-area-wrap" v-if="currentFun == 'excelToPdf' && pageId == 14" :fileList="fileList"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun "
    v-model:downUrl.sync="downInof.downUrl" />
  <!-- {{currentFun}}{{pageId}} -->
  <jpgToPdf class="fun-area-wrap" v-if="currentFun == 'jpgToPdf' && pageId == 13" v-model:fileList="fileList"
    v-model:uploadingFlag.sync="$store.state.uploadingFlag" v-model:downUrl.sync="downInof.downUrl"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @clickUpload="clickUpload" @changeRotate="changeRotate"
    @addPreImg="addPreImg" @changeFileListToJpgFileList="changeFileListToJpgFileList" />
  <failUpload class="fun-area-wrap" v-if="checkAppState() == 'failUpload'" />
  <!-- <failUpload class="fun-area-wrap" /> -->
  <!-- <converting class="fun-area-wrap" /> -->
  <failConvert class="fun-area-wrap" v-if="checkAppState() == 'failConvert'" />
  <!-- <failConvert class="fun-area-wrap" /> -->
  <download class="fun-area-wrap" v-if="checkAppState() == 'converted'" v-model:downUrl.sync="downInof.downUrl"
    v-model:downflag.sync="downInof.downflag" v-model:currentFun.sync="currentFun" />
  <!-- <warningPop v-if="warningFlag == true"></warningPop> -->
</template>

<script>
  // import warningPop from '../components/warningPop.vue'
  import converting from "../../components/common/converting.vue";
  import uploading from "../../components/common/uploading.vue";
  import failConvert from "../../components/common/failConvert.vue";
  import failUpload from "../../components/common/failUpload.vue";
  import download from '../../components/pc/Download'
  import PDF from 'pdfjs-dist'
  import { openPop, removeSingleFile } from '../../api/common'
  // import { convertFun } from "../components/convertFun/convert";
  import pdfToWord from "../../components/pc/convertFun/PDFtoFile/pdfToWord.vue";
  import pdfToImg from "../../components/pc/convertFun/PDFtoFile/pdfToImg.vue";
  import pdfToExcel from "../../components/pc/convertFun/PDFtoFile/pdfToExcel.vue";
  import pdfToTexT from "../../components/pc/convertFun/PDFtoFile/pdfToText.vue";
  import pdfToPPT from "../../components/pc/convertFun/PDFtoFile/pdfToPPT.vue";
  import pdfToHtml from "../../components/pc/convertFun/PDFtoFile/pdfToHtml.vue";
  import wordToPdf from "../../components/pc/convertFun/fileToPDF/wordToPdf.vue";
  import textToPdf from "../../components/pc/convertFun/fileToPDF/textToPdf.vue";
  import pptToPdf from "../../components/pc/convertFun/fileToPDF/pptToPdf.vue";
  import excelToPdf from "../../components/pc/convertFun/fileToPDF/excelToPdf.vue";
  import jpgToPdf from "../../components/pc/convertFun/fileToPDF/jpgToPdf.vue";
  import { gaEvent } from "@/utils/gaEvent";
  export default {
    data() {
      return {
        fileList: [],
        fileType: '',
        color: false,
        fileTypeUpload: "application/pdf",
        uploadingFlag: false,
        uploadSusFlag: false,
        warningFlag: false,
        currentFun: '',
        toFileTaret: '',
        clickFlag: false,
        uploadState: '0',
        fileLimited: 1,
        downInof: {
          downUrl: '',
          downName: '',
          downFileType: '',
        },
        htmlToPdf: {
          flag: false,
          url: '',
          urlWrongFlag: true,
          urlWrongText: '',
        },
        pdfImgList: [],
        preImgSrc: 'https://pdnob.passfab.com/images/pdnob1-4/icon-off-pdf2.svg',
        pages: 0,
        canvasList: [],
        occupancyFlag: true,
        getFileFromState: false,
        clickUploadFlag: false,
        pageId: 6,
        pageName: "",//当前页面是什么功能，埋点用
        fromPageName: "",//来自什么页面
        list: [
          {
            id: 6,
            src: require("../../assets/convert/PTW.svg"),
            text: this.$t(`message.Navigation.pdf.info[0]`),
            p: this.$t(`message.PdfTofile.text[0]`),
          },
          {
            id: 7,
            src: require("../../assets/convert/PTJ.svg"),
            text: this.$t(`message.Navigation.pdf.info[4]`),
            p: this.$t(`message.PdfTofile.text[3]`),
          },
          {
            id: 8,
            src: require("../../assets/convert/PTE.svg"),
            text: this.$t(`message.Navigation.pdf.info[1]`),
            p: this.$t(`message.PdfTofile.text[1]`),
          },
          {
            id: 9,
            src: require("../../assets/convert/PDTPP.svg"),
            text: this.$t(`message.Navigation.pdf.info[2]`),
            p: this.$t(`message.PdfTofile.text[2]`),
          },
          {
            id: 10,
            src: require("../../assets/convert/PTT.svg"),
            text: this.$t(`message.Navigation.pdf.info[3]`),
            p: this.$t(`message.PdfTofile.text[4]`),
          },
          {
            id: 11,
            src: require("../../assets/convert/PTH.svg"),
            text: this.$t(`message.Navigation.pdf.info[5]`),
            p: this.$t(`message.PdfTofile.text[5]`),
          },
          {
            id: 12,
            src: require("../../assets/convert/WTP.svg"),
            text: this.$t(`message.Navigation.pdf.info[6]`),
            p: this.$t(`message.PdfTofile.text[6]`),
          },
          {
            id: 13,
            src: require("../../assets/convert/ITP.svg"),
            text: this.$t(`message.Navigation.pdf.info[10]`),
            p: this.$t(`message.PdfTofile.text[10]`),
          },
          {
            id: 14,
            src: require("../../assets/convert/ETP.svg"),
            text: this.$t(`message.Navigation.pdf.info[7]`),
            p: this.$t(`message.PdfTofile.text[7]`),
          },
          {
            id: 15,
            src: require("../../assets/convert/PPTPD.svg"),
            text: this.$t(`message.Navigation.pdf.info[8]`),
            p: this.$t(`message.PdfTofile.text[8]`),
          },
          {
            id: 16,
            src: require("../../assets/convert/TTP.svg"),
            text: this.$t(`message.Navigation.pdf.info[9]`),
            p: this.$t(`message.PdfTofile.text[9]`),
          }
        ],
        totalFileCount: 0, //上传成功
        fileIndex: 0, //文件index
        firstScreen: 0, //loadFile筛选数量判断
        wsCount: 0,//最终转化成功的文件
        downType: 'docx',//下载类型
        reqApi: '/app/pdfConv',//转化接口
        imgFileListAll: [],//pdf转img
        wsCountImg: 0,//pdftoimg最终转化成功的文件
        fileSize: 0,//文件总大小
        processNowTime: '',//当前时间
        taskId: '',//任务id
        failCount: 0,//失败次数
      }
    },
    methods: {
      openPop,
      removeSingleFile,
      clearFileList() {
        this.fileList = []
      },
      removeFileFun() {
        ////console.log("remove");
        // this.uploadingFlag = false
        this.$store.commit('changeUploadingFlag', false)
        this.clickFlag = false
        this.fileList = []
      },
      removehtmlUrl() {
        this.htmlToPdf.urlWrongFlag = true
      },
      changeRotate(fileNum, addFlag) {
        console.log("fileNum：", fileNum, addFlag);
        this.fileList[fileNum].rotate += 90
      },
      changeFileListToJpgFileList(newObj) {
        this.fileList = newObj
      },
      checkAppState() {
        return this.$store.state.appState
      },
      changeClickOrDrag() {
        this.clickUploadFlag = true
      },

      //上传调用
      clickUpload(fileMsg, fileList) {
        console.log('fileMsg:', fileMsg, fileList)
        const _this = this
        if (this.$store.state.optCount.remaining == 0) {
          this.$store.commit('changeIsShowPur', true)
          gaEvent("buy_popup", {
            event_category: "Buy",
            event_label: this.$store.state.currentPage,
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (fileMsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "401003",
          });
          return
        }
        console.log("this.$store.state.userInfo.vip_expired_time:", this.$store.state.userInfo.vip_expired_time, this.processNowTime, this.$store.state.userInfo.vip_expired_time > this.processNowTime, fileList.length);
        // if (!this.$store.state.userInfo.vip_expired_time || (this.$store.state.userInfo.vip_expired_time < this.processNowTime && fileList.length > 8)) {
        if ((!this.$store.state.userInfo.vip_expired_time && fileList.length > 8) || (this.$store.state.userInfo.vip_expired_time < this.processNowTime && fileList.length > 8)) {

          gaEvent("buy_popup", {
            event_category: "Buy",
            event_label: this.$store.state.currentPage,
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (fileMsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "401001",
          });
          this.$store.commit('changeIsShowPur', true)
          return
        }
        if (!this.$store.state.userInfo.vip_expired_time && this.fileSize + fileMsg.size > 300 * 1024 * 1024 || this.$store.state.userInfo.vip_expired_time < this.processNowTime && this.fileSize + fileMsg.size > 300 * 1024 * 1024) {
          this.$store.commit('changeIsShowPur', true)
          gaEvent("buy_popup", {
            event_category: "Buy",
            event_label: this.$store.state.currentPage,
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (fileMsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "401002",
          });
          return
        }
        if (this.fileSize + fileMsg.size > 4 * 1024 * 1024 * 1024) {
          setTimeout(() => {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: _this.$t(`message.UploadFailed`),
            });
          }, 0)
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (fileMsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "402",
          });
          return
        }
        this.fileSize = this.fileSize + fileMsg.size
        this.fileIndex++
        this.totalFileCount = fileList.length
        this.morefileList = fileList
        let fileIndexId = _this.fileIndex - 1
        // this.fileType = this.fileList[0].name.toLowerCase().substr(this.fileList[0].name.lastIndexOf('.') + 1)
        let fileType = fileMsg.name.toLowerCase().substr(fileMsg.name.lastIndexOf('.') + 1)
        ////console.log('fileType:', this.fileType, this.fileList)
        // gaEvent("upload", {
        //   event_category: _this.pageName,
        //   event_label: "null",
        // });
        if (this.checkFile(fileMsg, _this.fileIndex - 1, fileType)) {
          console.log("-=-=-=-=:", _this.fileList);
          const file = fileMsg.raw
          fileMsg['document'] = file
          console.log("fileMsg:", fileMsg);
          fileMsg['rotate'] = 0
          this.fileList.push(fileMsg)
          let index = this.fileList.length - 1
          console.log("checkthis.fileList:", this.fileList);
          //多文件转化，成功的长度==正常文件的长度
          // if ((this.fileList.length == this.totalFileCount) && this.fileList.length > 0) {
          // console.log('成功的长度==正常文件的长度');
          this.uploadingFlag = true
          this.$store.commit('changeUploadingFlag', true)
          _this.$store.commit("changeAppState", "operate");
          if (this.checkAppState() == 'start') {
            this.checkFileType()
          }
          // this.clickFlag = true
          // const file = fileMsg.raw
          // this.fileList[this.fileList.length - 1]['document'] = file
          // this.fileList[this.fileList.length - 1]['rotate'] = 0
          // if (fileType == 'pdf') {
          console.log("this.pageId:", this.pageId);
          // if (this.pageId == 6 || this.pageId == 7 || this.pageId == 8 || this.pageId == 9 || this.pageId == 10 || this.pageId == 11) {
          //   console.log("fileMsg.raw:", fileMsg.raw);
          //   try {
          //     let reader = new FileReader()
          //     // this.addListeners(reader);
          //     reader.readAsDataURL(fileMsg.raw)
          //     reader.onload = () => {
          //       console.log("reader.result:", reader.result);
          //       _this.loadFile(reader.result, 'upload', index)
          //     }
          //     reader.onloadstart = () => {
          //       console.log("onloadstart");
          //     }
          //     reader.onloadend = () => {
          //       console.log("onloadend");
          //     }
          //     reader.onprogress = () => {
          //       console.log("onprogress");
          //     }
          //     reader.onerror = () => {
          //       console.log("失败了老弟");
          //     }
          //   } catch (e) {
          //     console.log("e:::", e);
          //   }

          //   // reader.addEventListener('loadend', () => {
          //   //   console.log("addEventListeneronloadend");
          //   // });
          // } else {
          this.uploadToServerProgress(fileIndexId)
          // }
          // }

        }
      },
      handleEvent(event) {
        console.log("=====:", `${event.type}: ${event.loaded} bytes transferred\n`);
      },

      addListeners(reader) {
        reader.addEventListener('loadstart', this.handleEvent);
        reader.addEventListener('load', this.handleEvent);
        reader.addEventListener('loadend', this.handleEvent);
        reader.addEventListener('progress', this.handleEvent);
        reader.addEventListener('error', this.handleEvent);
        reader.addEventListener('abort', this.handleEvent);
      },
      //多次上传
      reTry(times, uploadFile, num, _this) {
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, uploadFile, num, _this) {
            console.log('err===1110:', num)
            try {
              // //发送数据
              // xhr.send(formData);
              _this.$http
                .post('app/uploadFile', {
                  token: _this.$store.state.pdfToken,
                  'files[]': uploadFile.document,
                  task_id: _this.taskId
                }).then((res) => {
                  console.log("cehnggong:", num, res, _this.$store.state.appState);
                  let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                  // if (res.code == 200 && _this.$store.state.appState == "operate") {
                  if (res.code == 200) {
                    _this.uploadState = '1'
                    console.log("num====:", num);
                    _this.fileList[num]['fileUrl'] = res.data.response_file[0]
                    // _this.pdfUrl = res.data.response_file[0]
                    if (_this.pageId == 6 || _this.pageId == 7 || _this.pageId == 8 || _this.pageId == 9 || _this.pageId == 10 || _this.pageId == 11) {
                      // console.log("fileMsg.raw:", fileMsg.raw);
                      try {
                        let reader = new FileReader()
                        // this.addListeners(reader);
                        reader.readAsDataURL(_this.fileList[num].raw)
                        reader.onload = () => {
                          console.log("reader.result:", reader.result);
                          _this.loadFile(res.data.response_file[0], 'upload', num)
                        }
                        reader.onloadstart = () => {
                          console.log("onloadstart");
                        }
                        reader.onloadend = () => {
                          console.log("onloadend");
                        }
                        reader.onprogress = () => {
                          console.log("onprogress");
                        }
                        reader.onerror = () => {
                          console.log("失败了老弟");
                        }
                      } catch (e) {
                        console.log("e:::", e);
                      }

                      // reader.addEventListener('loadend', () => {
                      //   console.log("addEventListeneronloadend");
                      // });
                    }
                    _this.checkCurrentFun()
                    // convertFun(true)
                  } else {
                    _this.uploadState = '0'
                  }
                  gaEvent("upload_success1", {
                    event_category: _this.pageName,
                    event_label: (uploadFile.size / 1024 / 1024).toFixed(3),
                  });
                  gaEvent("upload_success2", {
                    event_category: _this.pageName,
                    event_label: `${updateTime}`,
                  });
                  _this.convertFun(true, num)
                  console.log("转化转化");
                  resolve(res)
                }).catch(function (err) {
                  // console.warn(Object.keys)
                  console.log("http: err:", err);
                  console.log("http: err:", Object.keys(err));
                  if (times > 0) {
                    setTimeout(() => {
                      reTryFunc(times - 1, uploadFile, num, _this)
                    })
                  } else {
                    let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                    // console.log("回去吧:", err);
                    if (_this.$store.state.appState == "start") return
                    _this.clearFileList()
                    _this.uploadState = '0'
                    _this.$store.commit("changeAppState", "failUpload");
                    _this.$message({
                      showClose: true,
                      duration: 8000,
                      type: "error",
                      message: "The network is abnormal. Please check the network connection.",
                    });
                    gaEvent("upload_fail1", {
                      event_category: _this.pageName,
                      event_label: (uploadFile.size / 1024 / 1024).toFixed(3),
                    });
                    gaEvent("upload_fail2", {
                      event_category: _this.pageName,
                      event_label: updateTime,
                    });
                    gaEvent("upload_fail3", {
                      event_category: _this.pageName,
                      event_label: "500",
                    });
                    reject(err)
                  }
                })
            } catch (err) {
              console.log('err===>', err)
            }
            // //console.log(`第${times}次重试`);

          }
          reTryFunc(times, uploadFile, num, _this)
        })
      },

      //上传文件并返回文件路径
      uploadToServerProgress(index) {
        console.log("uploadToServerProgress:", index);
        this.firstScreen++
        if (this.fileList.length > 0) {
          // let gaStartime = new Date().getTime();
          this.uploadState = '2'
          const _this = this
          // const num = this.fileList.length - 1
          const uploadFile = this.fileList[index].document
          ////console.log('uploadFile:', uploadFile)
          this.reTry(3, this.fileList[index], index, _this)
        }
      },

      checkFile(filemsg, index, fileType) {
        ////console.log("检测文件：", this.pageId);
        // let gaStartime = new Date().getTime()
        let _this = this
        if ((this.pageId == 6 || this.pageId == 7 || this.pageId == 8 || this.pageId == 9 || this.pageId == 10 || this.pageId == 11) && fileType != 'pdf') {
          ////console.log("选错了6:");
          // let uploadTime = (((new Date().getTime() - gaStartime) / 1000) * 100).toFixed(1) / 100
          this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          return false
        } else if (this.pageId == 12 && (fileType != 'doc' && fileType != 'docx')) {
          ////console.log("选错了12", this.fileType);
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          return false
        } else if ((fileType != 'jpg' && fileType != 'png' && fileType != 'bmp'
          && fileType != 'tiff' && fileType != 'gif'
          && fileType != 'jpeg' && fileType != 'tif') && this.pageId == 13) {
          ////console.log("选错了13：", this.fileType);
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          return false
        } else if (this.pageId == 14 && (fileType != 'xls' && fileType != 'xlsx')) {
          ////console.log("选错了14");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_file", {
            event_category: _this.pageName,
            event_label: "fail",
          });
          gaEvent("upload_fail", {
            event_category: _this.pageName,
            event_label: "format_error",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          return false
        } else if (this.pageId == 15 && (fileType != 'ppt' && fileType != 'pptx')) {
          ////console.log("选错了15");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          return false
        } else if (this.pageId == 16 && fileType != 'txt') {
          //console.log("选错了16");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          return false
        }
        //4g
        if (filemsg.size > 4 * 1024 * 1024 * 1024) {
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.UploadFailed`),
          });
          gaEvent("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          gaEvent("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          gaEvent("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.totalFileCount--
          // this.fileList.splice(index, 1)
          this.$store.commit("changeAppState", "start");
          return false
        }
        return true
      },
      checkFileType() {
        this.$store.commit('changeFileType', this.fileType)
        if (this.fileType == 'pdf') {
          this.$store.commit('checkPDF', true)
          //console.log("--------------");
        } else {
          this.$store.commit('checkPDF', false)
        }
      },
      //check current funcion and save in vuex
      //if filyType is pdf ,change to step operate,else change step to operate after click current fun.
      //如果文件类型不为pdf则下载类型改为pdf存到vuex中，如果文件类型不为pdf则下载类型改为pdf存到vuex中
      checkCurrentFun(targetCurrent, targetFileType) {
        console.log("this.fileType:", this.fileType, this.pageId);
        if (this.pageId == 6) {
          targetCurrent = 'pdfToWord'
          targetFileType = 'docx'
          this.fileType = 'pdf'
        } else if (this.pageId == 7) {
          targetCurrent = 'pdfToImg'
          targetFileType = 'zip'
          this.fileType = 'pdf'
        } else if (this.pageId == 8) {
          targetCurrent = 'pdfToExcel'
          targetFileType = 'xlsx'
          this.fileType = 'pdf'
        } else if (this.pageId == 9) {
          targetCurrent = 'pdfToPPT'
          targetFileType = 'pptx'
          this.fileType = 'pdf'
        } else if (this.pageId == 10) {
          targetCurrent = 'pdfToText'
          targetFileType = 'txt'
          this.fileType = 'pdf'
        } else if (this.pageId == 11) {
          targetCurrent = 'pdfToHtml'
          targetFileType = 'zip'
          this.fileType = 'pdf'
        } else if (this.pageId == 13) {
          console.log("jpgtopdf");
          this.currentFun = 'jpgToPdf'
        }
        if (this.fileType != 'pdf') {
          this.downInof.downFileType = 'pdf'
          // this.$store.commit('changeAppState', 'operate')
        }
        if (this.pageId == 12) {
          this.currentFun = 'wordToPdf'
          // window.location.href='./convert?from_toplist&from_other_excel'
        } else if (this.pageId == 16) {
          this.currentFun = 'textToPdf'
        } else if (this.pageId == 15) {
          this.currentFun = 'pptToPdf'
        } else if (this.pageId == 14) {
          this.currentFun = 'excelToPdf'
        } else if (this.pageId == 13) {
          console.log("jpg");
          this.currentFun = 'jpgToPdf'
          this.fileLimited = 8
        }
        //console.log("targetCurrent:", targetCurrent);
        if (targetCurrent) {
          // const _this = this
          console.log("11111:", targetCurrent);
          this.currentFun = targetCurrent
          this.downInof.downFileType = targetFileType
          this.$store.commit('changeAppState', 'operate')
        }
        //console.log("---------:", this.currentFun, this.pageId);
      },
      //转化
      reTryProcess(times, api, currentFun, _this, index) {
        console.log("downType:", _this.downType);
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, api, currentFun, _this) {
            // //console.log(`第${times}次重试`);
            _this.$http.post(api, {
              token: _this.$store.state.pdfToken,
              file_url: _this.fileList[index].fileUrl,
              conv: _this.downType,
            }).then((response) => {
              //console.log(response);
              if (response.code == 200) {
                _this.convertNumber = response.data.result[0].number;
                //if targetType is html,request ws should add a parameter zip
                // let gaStartime = new Date().getTime()
                const zipFlag = _this.pageId == 11 ? true : false

                //console.log("webscoket:", _this.$store.state.pdfToken, _this.convertNumber);
                _this.$http
                  .ws(_this.$store.state.pdfToken, _this.convertNumber, zipFlag)
                  .then((response) => {
                    if (response.data.status == 1 && _this.$store.state.appState == 'converting') {
                      _this.fileList[index].downUrl = response.data.output_files[0];
                      // _this.$emit("update:downUrl", response.data.output_files[0]);
                      // _this.$store.commit("changeAppState", "converted");
                      let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      gaEvent("process_success2", {
                        event_category: _this.pageName,
                        event_label: `${covertTime}`,
                      });
                      gaEvent("process_success1", {
                        event_category: _this.pageName,
                        event_label: "1",
                      });
                      gaEvent("process_success3", {
                        event_category: _this.pageName,
                        event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
                      });
                      // gaEvent("process1", {
                      //   event_category: _this.pageName,
                      //   event_label: "success",
                      // });
                      _this.wsCount++ //转化成功
                    } else if (response.data.status != 1 && response.data.status != 2 && _this.$store.state.appState == 'converting') {
                      //websocket失败
                      let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      gaEvent("process_fail1", {
                        event_category: _this.pageName,
                        event_label: covertTime,
                      });
                      gaEvent("process_fail3", {
                        event_category: _this.pageName,
                        event_label: "501",
                      });
                      gaEvent("process_fail2", {
                        event_category: _this.pageName,
                        event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
                      });
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      });
                      _this.wsCount++ //转化成功
                      _this.failCount++ //转化成功
                      // _this.$store.commit("changeAppState", "failConvert");
                    }
                  }).catch((response) => {
                    //console.log(response);
                    if (_this.$store.state.appState == 'converting') {
                      let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      // gaEvent("process_fail", {
                      //   event_category: _this.pageName,
                      //   event_label: covertTime,
                      // });
                      // gaEvent("process_fail", {
                      //   event_category: _this.pageName,
                      //   event_label: "service_error",
                      // });
                      // gaEvent("process_file", {
                      //   event_category: _this.pageName,
                      //   event_label: "fail",
                      // });
                      gaEvent("process_fail1", {
                        event_category: _this.pageName,
                        event_label: covertTime,
                      });
                      gaEvent("process_fail3", {
                        event_category: _this.pageName,
                        event_label: "500",
                      });
                      gaEvent("process_fail2", {
                        event_category: _this.pageName,
                        event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
                      });
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      });
                      // _this.$store.commit("changeAppState", "failConvert");
                    }
                    _this.failCount++
                    _this.wsCount++ //转化成功
                  });
              }
              // else {}
              resolve(response)
            }).catch((err) => {
              if (times > 0) {
                setTimeout(() => {
                  reTryFunc(times - 1, api, currentFun, _this)
                })
              } else {
                _this.wsCount++ //转化失败也加
                _this.failCount++
                // _this.$store.commit("changeAppState", "failConvert");
                _this.$message({
                  showClose: true,
                  duration: 8000,
                  type: "error",
                  message: "The network is abnormal. Please check the network connection.",
                });
                reject(err)
              }
            })
          }
          reTryFunc(times, api, currentFun, _this)
        })
      },
      //转化功能
      convertFun(firstFlag, index) {
        let currentFun = this.currentFun.replace(/pdf/i, 'PDF').replace('To', ' to ').replace('word', 'Word').replace(/text/i, 'TEXT').replace('ppt', 'PPT').replace('excel', 'Excel').replace('jpg', 'Images').replace('Img', 'jpg').replace(/html/i, 'HTML')
        const _this = this;
        console.log("convertFun:_this:", _this.fileList);
        this.canConverFlag = true;
        this.$store.commit("changeAppState", "converting");
        console.log("convetfiles:", _this.fileList, _this.fileList[index]);
        //console.log("this.$store:", this.$store);
        // const curReqApi = this.reqApi ? this.reqApi : '/app/pdfConv'
        if (_this.pageId == 7) {
          _this.convertImg(index)
        } else {
          _this.reTryProcess(3, this.reqApi, currentFun, _this, index)
        }
      },
      convertImg(index) {
        const _this = this;

        console.log("convertImg:", index, _this.fileList, _this.fileList[index]);
        this.gaStartime = new Date().getTime();
        this.canConverFlag = true;
        this.$store.commit("changeAppState", "converting");
        console.log("this.fileList[index].fileUrl:", _this.fileList[index], this.fileList[index]);
        this.$http
          .post("/app/pdfConv", {
            token: _this.$store.state.pdfToken,
            file_url: _this.fileList[index].fileUrl,
            conv: "jpg",
          })
          .then((response) => {
            ////console.log(response);
            if (response.code == 200) {
              console.log("pdfConv成功:", response);
              this.convertNumber = response.data.result[0].number;
              _this.$http
                .ws(_this.$store.state.pdfToken, _this.convertNumber)
                .then((response) => {
                  console.log("开始ws");
                  let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                  _this.imgFileListAll = _this.imgFileListAll.concat(response.data.output_files);
                  console.log("response.data.output_files:", response.data.output_files);
                  console.log("_this.imgFileListAll:", _this.imgFileListAll);
                  _this.wsCountImg++
                  gaEvent("process_success2", {
                    event_category: _this.pageName,
                    event_label: `${covertTime}`,
                  });
                  gaEvent("process_success1", {
                    event_category: _this.pageName,
                    event_label: "1",
                  });
                  gaEvent("process_success3", {
                    event_category: _this.pageName,
                    event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
                  });
                })
                .catch((response) => {
                  console.log("失败ws");
                  let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                  // gaEvent("process_fail", {
                  //   event_category: _this.pageName,
                  //   event_label: covertTime,
                  // });
                  // gaEvent("process_fail", {
                  //   event_category: _this.pageName,
                  //   event_label: "ws_service_error",
                  // });
                  // gaEvent("process_file", {
                  //   event_category: _this.pageName,
                  //   event_label: "fail",
                  // });
                  gaEvent("process_fail1", {
                    event_category: _this.pageName,
                    event_label: covertTime,
                  });
                  gaEvent("process_fail3", {
                    event_category: _this.pageName,
                    event_label: "501",
                  });
                  gaEvent("process_fail2", {
                    event_category: _this.pageName,
                    event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
                  });
                  _this.$message({
                    showClose: true,
                    duration: 8000,
                    type: "error",
                    message: "The network is abnormal. Please check the network connection.",
                  });
                  _this.$store.commit("changeAppState", "failConvert");
                  _this.wsCountImg++
                });
            } else {
              // _this.openConverWrong();
              let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
              // gaEvent("process_fail", {
              //   event_category: _this.pageName,
              //   event_label: covertTime,
              // });
              // gaEvent("process_file", {
              //   event_category: _this.pageName,
              //   event_label: "fail",
              // });
              // gaEvent("process_fail", {
              //   event_category: _this.pageName,
              //   event_label: "service_error",
              // });
              gaEvent("process_fail1", {
                event_category: _this.pageName,
                event_label: covertTime,
              });
              gaEvent("process_fail3", {
                event_category: _this.pageName,
                event_label: "500",
              });
              gaEvent("process_fail2", {
                event_category: _this.pageName,
                event_label: `${(_this.fileList[index].size / 1024 / 1024).toFixed(3)}`,
              });
              _this.$message({
                showClose: true,
                duration: 8000,
                type: "error",
                message: "The network is abnormal. Please check the network connection.",
              });
              _this.$store.commit("changeAppState", "failConvert");
              _this.wsCountImg++
            }
          })
          .catch((response) => {
            _this.wsCountImg++

          });
      },

      addPreImg(num, imgBlob) {
        console.log("addPreImg");
        this.fileList[num].preImg = imgBlob
      },
      //加载pdf判断是否是破损文件
      loadFile(url, name, index) {
        console.log("url,name,index:", url, name, index);
        const _this = this
        PDF.getDocument(url)
          .then((pdf) => {
            console.log('enter loadFile', pdf)
            _this.pdfDoc = pdf
            console.log('enter loadFile1', pdf)
            _this.pages = this.pdfDoc.numPages
            console.log('enter loadFile2', pdf)
            // _this.uploadToServerProgress(index)
          })
          .catch(function (e) {
            // _this.fileList.splice(index, 1)
            console.log("--=-=-=打不开:", e);
            _this.fileList[index].isUnsuite = true //筛选条件的属性
            // if (_this.fileList.length == 1) {
            //   _this.$store.commit('changeAppState', 'start')
            //   _this.$store.commit('changeUploadingFlag', false)
            //   _this.clickFlag = false
            //   _this.openPop(_this, 4)
            //   console.log("this.fileList:", _this.fileList);
            // }
            gaEvent("upload_fail1", {
              event_category: _this.pageName,
              event_label: (_this.fileList[index].size / 1024 / 1024).toFixed(3),
            });
            gaEvent("upload_fail2", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            gaEvent("upload_fail3", {
              event_category: _this.pageName,
              event_label: "400",
            });
            return false
          })
          .finally(() => {
            //当所有文件都loadFile之后
            let failarray = _this.fileList.filter((item, index) => item.isUnsuite)
            console.log("failarray:", failarray);
            if (failarray.length == _this.fileList.length) {
              this.$store.commit('changeAppState', 'start')
              _this.$store.commit('changeUploadingFlag', false)
              _this.clickFlag = false
              _this.openPop(_this, 4)
              _this.fileList = []
            }
          })
      },
      //监听路由改变pageId
      changePageId() {
        //console.log("this.$router.fullPath:", this.$route.fullPath);
        if (this.$route.fullPath.indexOf('to_word') > 0) {
          //console.log(666);
          this.pageId = 6
          this.pageName = "PDF_to_Word"
          this.downType = 'docx'
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_jpg') > 0) {
          //console.log(777);
          this.pageId = 7
          this.pageName = "PDF_to_JPG"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_excel') > 0) {
          //console.log(888);
          this.pageId = 8
          this.pageName = "PDF_to_Excel"
          this.downType = 'xlsx'
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_ppt') > 0) {
          //console.log(999);
          this.pageId = 9
          this.pageName = "PDF_to_PPT"
          this.downType = 'pptx'
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_text') > 0) {
          this.pageId = 10
          this.pageName = "PDF_to_TXT"
          this.downType = 'txt'
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_html') > 0) {
          this.pageId = 11
          this.pageName = "PDF_to_HTML"
          this.downType = 'html'
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('from_other_word') > 0) {
          this.pageId = 12
          this.pageName = "Word_to_PDF"
          this.downType = 'pdf'
          this.reqApi = '/app/wordConv'
          this.fileTypeUpload = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        } else if (this.$route.fullPath.indexOf('from_other_jpg') > 0) {
          this.pageId = 13
          this.downType = 'pdf'
          this.pageName = "Images_to_PDF"
          this.fileTypeUpload = ".png,.jpg,.bmp,.tiff,.tif,.jpeg,.gif"
        } else if (this.$route.fullPath.indexOf('from_other_excel') > 0) {
          this.pageId = 14
          this.downType = 'pdf'
          this.pageName = "Excel_to_PDF"
          this.reqApi = '/app/excelConv'
          this.fileTypeUpload = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        } else if (this.$route.fullPath.indexOf('from_other_ppt') > 0) {
          this.pageId = 15
          this.pageName = "PPT_to_PDF"
          this.downType = 'pdf'
          this.reqApi = '/app/pptConv'
          this.fileTypeUpload = ".ppt,.pptx"
        } else if (this.$route.fullPath.indexOf('from_other_text') > 0) {
          this.pageId = 16
          this.pageName = "TXT_to_PDF"
          this.downType = 'pdf'
          this.reqApi = '/app/txtConv'
          this.fileTypeUpload = ".txt"
        }
      },

      beforeUpload(e) {
      },
    },
    components: {
      converting,
      download,
      failConvert,
      failUpload,
      pdfToWord,
      pdfToImg,
      pdfToExcel,
      pdfToTexT,
      pdfToPPT,
      pdfToHtml,
      wordToPdf,
      textToPdf,
      pptToPdf,
      excelToPdf,
      jpgToPdf,
      uploading
    },
    watch: {
      $route: {
        handler(to, from) {
          this.changePageId()
          console.log("路由变了哦:", to, from);
        },
        deep: true
      },
      fileList: {
        handler(val) {
          const _this = this
          if (val.length > 0) {
            this.downInof.downName = val[0].name
          } else {
            _this.clickUploadFlag = false
          }
          this.$store.commit('changeAppFileList', val)
        },
        deep: true, //true 深度监听
      },
      pdfImgList: {
        handler(val) {
          this.preImgSrc = val[0]
        },
        deep: true, //true 深度监听
      },
      wsCount: {
        handler(val) {
          if (val == this.firstScreen) {
            let len = this.fileList.filter((item) => item.downUrl)
            let _this = this
            if (this.pageId == 7) return
            if (val == 1) {
              if (_this.failCount == 1) {
                this.$store.commit("changeAppState", "failConvert");
                return
              }
              //单文件
              _this.downInof.downUrl = this.fileList[0].downUrl;
              if (_this.pageId == 11) _this.downType = "zip";
              _this.downInof.downFileType = _this.downType;
              this.$emit("update:downUrl", _this.downInof.downUrl);
              this.$store.commit("changeAppState", "converted");
            } else {
              if (_this.failCount == val) {
                this.$store.commit("changeAppState", "failConvert");
                return
              }
              //多文件
              let formData = new FormData();
              formData.append("token", this.$store.state.pdfToken);
              formData.append("task_id", this.taskId);
              for (let i = 0; i < len.length; i++) {
                formData.append("file_url[]", len[i].downUrl);
                console.log("len[i]:", len[i]);
              }

              this.$http
                .post("/app/compressFile", formData).then((res) => {
                  console.log("多文件去水印");
                  if (res.code == 200) {
                    _this.downInof.downUrl = res.data.result;
                    _this.downInof.downFileType = "zip";
                    console.log("下载链接：", res.data.result);
                    this.$emit("update:downUrl", _this.downInof.downUrl);
                    this.$store.commit("changeAppState", "converted");
                  }
                })
            }

          }
        },
        deep: true, //true 深度监听
      },
      wsCountImg: {
        handler(val) {
          console.log("val:", val, this.firstScreen);
          if (val == this.firstScreen) {
            let _this = this
            //多文件
            let formData = new FormData();
            formData.append("token", this.$store.state.pdfToken);
            formData.append("task_id", this.taskId);
            for (let i = 0; i < _this.imgFileListAll.length; i++) {
              formData.append("file_url[]", _this.imgFileListAll[i]);
            }
            this.$http
              .post("/app/compressFile", formData).then((res) => {
                console.log("多文件去水印:", res, res.code == 200);
                if (res.code == 200) {
                  console.log("======");
                  _this.downInof.downUrl = res.data.result;
                  _this.downInof.downFileType = "zip";
                  console.log("下载链接Img：", res.data.result);
                  this.$emit("update:downUrl", _this.downInof.downUrl);
                  this.$store.commit("changeAppState", "converted");
                }
              })

          }
        },
        deep: true, //true 深度监听
      },
      currentFun: function () { },
    },
    created() {
      this.$store.commit('changeAppState', 'start')
    },
    mounted() {
      //console.log("route:", this.$route);
      // window.addEventListener('dragover', (e) => //console.log(111))
      //console.log("Convertmounted:", this.$route);
      this.$http
        .get("/app/user/get-taskid").then((res) => {
          this.taskId = res.data.task_id
          console.log("res.data:", res.data);
        })
      this.processNowTime = Math.round(new Date().valueOf() / 1000)
      console.log("this.nowTime:", this.processNowTime, this.$store.state.userInfo.vip_expired_time);
      this.pageName = "PDF_to_Word"
      this.fileTypeUpload = "application/pdf"
      // this.uploadingFlag = false
      this.$store.commit('changeUploadingFlag', false)
      // this.checkConverFunUseful()
      this.changePageId()
      this.clearFileList()
      if (this.pageId == 13) {
        this.checkCurrentFun()
      }

      // this.fromPage()
    },
  }
</script>

<style lang="scss" scoped>
  .fun-area-wrap {
    height: 548px;
    // overflow: auto;
    background: #fff;
    border-radius: 16px;
    // margin-top: 20px;
  }

  .action-area-wrap {
    padding: 24px 20px;

    .cell {
      width: 92px;
      text-align: center;
      padding: 15px 0;
      color: #001637;
      opacity: 0.6;
      font-size: 14px;
      font-weight: 300;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 16px;

      &.active {
        opacity: 1;

        img {
          display: none;
        }

        img.fun-active {
          display: initial;
        }
      }

      .fun-active {
        display: none;
      }

      &.active:hover {
        cursor: pointer;
        border: 1px solid #278bff;
        color: #001637;
        opacity: 1;
        font-weight: 500;
      }
    }
  }

  .flexBox {
    .ptw {
      width: 158px;
      height: 108px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dotteBox {
    /* height: 95%;
    width: 95%; */
    padding: 24px;
    border-radius: 16px;
    /* margin: calc(1.5% - 4px) 0 0 2.5%; */
  }

  .el-upload,
  .el-upload-dragger {
    cursor: initial;
  }

  ::v-deep .el-upload {
    &:focus {
      color: black;
      /* border-color: red */
    }
  }

  [uploadflag='true'] {
    button {
      opacity: 0.6;
    }
  }

  .pre-pdf-img {
    box-shadow: 0px 4px 12px rgba(33, 34, 42, 0.1);
  }

  ::v-deep .is-dragover {
    background-color: #f7f6ff !important;
  }
</style>