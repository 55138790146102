const changeType = function (targetType) {
  ////console.log("targetType:", targetType);
  this.targetType = targetType;
  this.$emit("update:downFileType", this.targetType);
};
const checkAppState = function () {
  return this.$store.state.appState;
};
const reTry = function (times, api, currentFun, _this) {
  ////console.log("retry");
  let gaStartime = new Date().getTime()
  return new Promise((resolve, reject) => {
    function reTryFunc(times, api, currentFun, _this) {
      // //console.log(`第${times}次重试`);
      // //console.log("----------", _this)
      // //console.log("----------", _this.fileList)
      // //console.log("----------", _this.fileList[0].fileUrl)
      _this.$http.post(api, {
        token: _this.$store.state.pdfToken,
        file_url: _this.fileList[0].fileUrl,
        conv: _this.targetType,
      }).then((response) => {
        //console.log(response);
        if (response.code == 200) {
          _this.convertNumber = response.data.result[0].number;
          //if targetType is html,request ws should add a parameter zip
          // let gaStartime = new Date().getTime()
          const zipFlag = _this.targetType == 'html' ? true : false
          //console.log("webscoket:", _this.$store.state.pdfToken, _this.convertNumber);
          _this.$http
            .ws(_this.$store.state.pdfToken, _this.convertNumber, zipFlag)
            .then((response) => {
              //console.log("========:", response);
              //websocket成功
              //console.log("websocket:", _this.$store.state.appState);

              if (response.data.status == 1 && _this.$store.state.appState == 'converting') {

                _this.$emit("update:downUrl", response.data.output_files[0]);
                // if (checkAppState() == "start") return
                _this.$store.commit("changeAppState", "converted");
                let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                _this.$gtag.event("process_success", {
                  event_category: _this.$parent.pageName,
                  event_label: `${covertTime}-${(_this.fileList[0].size / 1024 / 1024).toFixed(3)}`,
                });
                _this.$gtag.event("process_success", {
                  event_category: _this.$parent.pageName,
                  event_label: "1",
                });
                _this.$gtag.event("process_file", {
                  event_category: _this.$parent.pageName,
                  event_label: "success",
                });
              } else if (response.data.status != 1 && response.data.status != 2 && _this.$store.state.appState == 'converting') {
                //websocket失败
                let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                _this.$gtag.event("process_fail", {
                  event_category: _this.$parent.pageName,
                  event_label: covertTime,
                });
                _this.$gtag.event("process_fail", {
                  event_category: _this.$parent.pageName,
                  event_label: "ws_service_error",
                });
                _this.$gtag.event("process_file", {
                  event_category: _this.$parent.pageName,
                  event_label: "fail",
                });
                _this.$message({
                  showClose: true,
                  duration: 8000,
                  type: "error",
                  message: "The network is abnormal. Please check the network connection.",
                });
                _this.$store.commit("changeAppState", "failConvert");
                // _this.$confirm("<p class='pop-tit'>" + _this.$t(`message.convertConfirm.error`) + "</p><p class='pop-text'>" + this.$t(`message.convertConfirm.title`) + "</p>", '', {
                //   confirmButtonText: _this.$t(`message.convertConfirm.confirmButtonText`),
                //   cancelButtonText: _this.$t(`message.convertConfirm.cancelButtonText`),
                //   confirmButtonClass: 'confirmBtn',
                //   cancelButtonClass: 'cancleBtn',
                //   customClass: 'openPop-btn-group',
                //   dangerouslyUseHTMLString: true,

                // })
                //   .then(() => {
                //     _this.convertFun();
                //   })
                //   .catch(() => {
                //     // this.$store.commit("changeAppState", "operate");
                //     // this.clickCovertFlag = false
                //     _this.$router.go(0)

                //   });
              }
            }).catch((response) => {
              //console.log(response);
              if (_this.$store.state.appState == 'converting') {
                let covertTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                _this.$gtag.event("process_fail", {
                  event_category: _this.$parent.pageName,
                  event_label: covertTime,
                });
                _this.$gtag.event("process_fail", {
                  event_category: _this.$parent.pageName,
                  event_label: "service_error",
                });
                _this.$gtag.event("process_file", {
                  event_category: _this.$parent.pageName,
                  event_label: "fail",
                });
                _this.$message({
                  showClose: true,
                  duration: 8000,
                  type: "error",
                  message: "The network is abnormal. Please check the network connection.",
                });
                _this.$store.commit("changeAppState", "failConvert");
              }
            });
        }
        // else {}
        resolve(response)
      }).catch((err) => {
        if (times > 0) {
          setTimeout(() => {
            reTryFunc(times - 1, api, currentFun, _this)
          })
        } else {
          _this.$store.commit("changeAppState", "failConvert");
          _this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: "The network is abnormal. Please check the network connection.",
          });
          reject(err)
        }
      })
    }
    reTryFunc(times, api, currentFun, _this)
  })
}
const convertFun = function (firstFlag) {
  //console.log("============", this)
  //console.log("============", this.$parent.$parent)
  //console.log("============", this.$parent.$parent.currentFun)
  //console.log("============", this.fileList[0].fileUrl)
  //console.log("firstFlag:", firstFlag)

  let currentFun = this.$parent.currentFun.replace(/pdf/i, 'PDF').replace('To', ' to ').replace('word', 'Word').replace(/text/i, 'TEXT').replace('ppt', 'PPT').replace('excel', 'Excel').replace('jpg', 'Images').replace('Img', 'jpg').replace(/html/i, 'HTML')
  // firstFlag ? window.ga('send', 'event', currentFun, 'Click Convert') : ''
  firstFlag ? firstFlag : ''
  //console.log("currentFun:", currentFun)
  // let gaStartime = new Date().getTime()
  const _this = this;
  this.canConverFlag = true;
  this.$store.commit("changeAppState", "converting");
  //console.log("this.$store:", this.$store);
  const curReqApi = this.reqApi ? this.reqApi : '/app/pdfConv'
  reTry(3, curReqApi, currentFun, _this)

}
const backToChooseType = function () {
  this.$store.commit("changeAppState", "start");
  setTimeout(() => {
    this.$emit('checkFileType')

  }, 100);
}
const clickConvert = function () {
  this.clickCovertFlag = true;
  this.$store.commit("changeAppState", "converting");
}
function removeSingleFile(fileNum) {
  console.log("this.$parent:", this.$parent);
  this.$parent.fileList.splice(fileNum, 1)
  this.$parent.fileList == 0 ? this.$store.commit("changeAppState", "start") : ''
  this.$parent.clickFlag = false
  // this.$parent.uploadingFlag = false
  this.$store.commit('changeUploadingFlag', true)
  ////console.log(this.$parent.uploadingFlag)
  // if (document.querySelectorAll('.can-convert-list .cell').length > 0) {
  //   document.querySelectorAll('.can-convert-list .cell').forEach(index => {
  //     index.classList.remove('active')
  //   })
  // }
}
export {
  changeType,
  checkAppState,
  convertFun,
  backToChooseType,
  clickConvert,
  removeSingleFile,
  reTry
}