<template lang="">
  <div id="converting">
    <p class="converting-firstP">{{$t(`message.Generating.title`)}}</p>
    <p class="converting-secondP">{{$t(`message.UploadFun.uploading[0]`)}}</p>
    <img class='converting' src="../../assets/convert/generating.gif" alt="">
    <div class="cancelBtn" @click="Cancel">
      <span>Cancel</span>
    </div>
  </div>

</template>
<script>
  import { gaEvent } from "@/utils/gaEvent";
  export default {
    data() {
      return {
        convertingText: '',
        // timer: null,
        convertTime: "",
        gaStartime: 0
      }
    },
    watch: {},
    mounted() {
      console.log("_this.$parent.$parent.$parent1:", this.$parent.$parent);
      console.log("_this.$parent.$parent.$parent2:", this.$parent.$parent.$parent);
      console.log("_this.$parent.$parent.$parent3:", this.$parent.$parent.$parent.pageName);
      console.log("_this.$parent.$parent.$parent4:", this.$parent.$parent.pageName);
      this.convertingText = this.$t(`message.UploadFun.uploading[0]`)
      this.convertTime = new Date().getTime()
      // let _this = this
      this.$store.commit('changeProcessTime', this.convertTime)
    },

    methods: {
      Cancel() {
        let _this = this
        sessionStorage.setItem('abandon', true)
        this.$confirm('It only takes a few minutes to complete, and abandoning now will not save the settings.', {
          confirmButtonText: 'Leave',
          cancelButtonText: 'Cancel',
          closeOnClickModal: false
        }).then(() => {
          let covertTime = Math.floor((new Date().getTime() - _this.convertTime) / 1000)
          if (navigator.userAgent.indexOf('pdfName') > 0) {
            console.log("_this.$parent.$parent.pageName_this.$parent.$parent.pageName:", _this.$parent.$parent.pageName);
            gaEvent("process_giveup", {
              event_category: _this.$parent.$parent.$parent.pageName ? _this.$parent.$parent.$parent.pageName : _this.$parent.$parent.pageName,
              event_label: covertTime
            });
          } else {
            console.log("_this.$parent.$parent.pageName_this.$parent.$parent.pageName1:", _this.$parent.$parent.pageName);
            this.$gtag.event("cancel_giveup", {
              event_category: _this.$parent.$parent.$parent.pageName ? _this.$parent.$parent.$parent.pageName : _this.$parent.$parent.pageName,
              event_label: covertTime
            });
          }

          setTimeout(() => {
            sessionStorage.setItem('abandon', false)//离开不需要浏览器的拦截
            ////console.log("sessionStorage.setItem('abandon', false):", sessionStorage.getItem('abandon'));
          }, 0)
          this.$router.go()
        }).catch(() => {
          setTimeout(() => {
            sessionStorage.setItem('abandon', false)//离开不需要浏览器的拦截
            ////console.log("sessionStorage.setItem('abandon', false):", sessionStorage.getItem('abandon'));
          }, 0)
          // sessionStorage.setItem('abandon', false)//离开不需要浏览器的拦截
        });
      }
    }
  }
</script>
<style lang="scss" scoped>
  @keyframes rotate {
    from {
      transform: rotate(-90deg);
    }

    to {
      transform: rotate(270deg);
    }
  }

  #converting img.converting {
    width: 180px;
    /* animation: rotate 2s cubic-bezier(.95, .46, .43, .8) infinite; */
  }

  #converting {
    background: #ffffff;
    border-radius: 16px;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    padding: 100px 0 0 0;

    .converting-firstP {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #1D1D1F;
    }

    .converting-secondP {
      margin: 10px 0 40px 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: rgba(29, 29, 31, 0.7);
    }

    .cancelBtn {
      /* padding: 7px 8px; */
      margin-top: 30px;
      line-height: 30px;
      border: 1px solid #5452f6;
      border-radius: 80px;
      width: 110px;
      height: 32px;
      color: #5452f6;
      text-align: center;
      cursor: pointer;

      &:hover {
        background: #3E3BFF;
        color: white;
        box-shadow: 0px 5px 8px rgba(84, 82, 246, 0.3);
      }

      &:active {
        background: #3432D6;
        color: white;
        box-shadow: 0px 5px 8px rgba(84, 82, 246, 0.3);
      }
    }
  }
</style>