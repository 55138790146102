<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import "../convert.scss";
  import { changeType, checkAppState, convertFun, backToChooseType } from "../convert";
  import converting from '../../common/converting.vue'

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "docx",
        fileName: this.fileList[0]?.name
      };
    },
    props: ["fileList"],

    components: {
      // pdf,
      converting

    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      backToChooseType
    },
    mounted() {
      ////console.log("进来了PDTTOWORD", this.checkAppState(), this.fileList.length > 0);
      if (this.checkAppState() == 'operate' && this.fileList.length > 0) {
        this.convertFun(true)
      }
    },
    watch: {
      fileList: {
        handler(val) {
          const _this = this
          ////console.log("file变了");
          if (val.length > 0) {
            this.convertFun(true)
          }
        },
        deep: true, //true 深度监听
      },
    }
  };
</script>
<style lang="scss" scoped>
</style>