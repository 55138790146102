<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />

  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import { changeType, checkAppState, convertFun, backToChooseType } from "../convert";
  import converting from '../../../common/converting.vue'

  import "../convert.scss";

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "pptx",
        fileName: this.fileList[0].name
      };

    },
    props: ["fileList"],

    components: {
      // pdf,
      converting

    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      backToChooseType
    },
    mounted() {
      // if (this.checkAppState() == 'operate') {
      //   this.convertFun(true)
      // }

    },
  };
</script>
<style lang="scss" scoped>


</style>