<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import { changeType, checkAppState, convertFun, removeSingleFile } from "../convert";
  import converting from '../../../common/converting.vue'

  import "../convert.scss";
  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: 'pdf',
        reqApi: "/app/pptConv",
        fileName: this.fileList[0].name

      };
    },
    props: ["fileList"],

    components: {
      // pdf,
      converting

    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      removeSingleFile
    },
    watch: {

    },
    mounted() {


    },
  };
</script>
<style lang="scss" scoped>
  .action-area-wrap {
    display: flex;
    align-items: flex-end;

  }


  .fun-area-wrap {
    text-align: left;

  }

  .pre-pdf-wrap .pre-pdf-content {
    box-shadow: none;
    border-radius: 0;
    background: none;
  }

  .pre-pdf-content:after {
    display: none !important;

  }

  .file-name-wrap {
    display: flex;
    margin-top: 1rem;
    max-width: 150px;
    margin: 0 auto;
    justify-content: center;

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }
  }
</style>