<template lang="">
  <!-- {{checkAppState()}}{{currentFun}}{{pageId}} -->
  <img src="https://pdnob-test-api.afirstsoft.cn/static/1665192957nbs/download/b4738b58958eb5b055aac2ab8addb408.tif"
    alt="">
  <div class="fun-area-wrap" v-if="checkAppState() == 'start' ">
    <div @click="changeClickOrDrag()">
      <el-upload class="upload-demo upload-area dotteBox" multiple drag ref="upload" action="" :auto-upload="false"
        :show-file-list="false" :on-change="(file,fileList)=>clickUpload(file,fileList,'click')" :file-list="fileList"
        :accept="fileTypeUpload">
        <div class="flexBox">
          <div style="display:flex;flex-direction: column;align-items: center;">
            <img class="ptw" src="../../../assets/convert/ITP.svg" alt="">
            <p
              style="font-family: 'Inter';font-style: normal;font-size:32px;font-weight:bold;margin:40px 0 8px 0px;line-height: 44px;">
              {{$t(`message.Navigation.pdf.info[10]`)}}</p>
            <p
              style="font-family: 'Inter'; font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.PdfTofile.text[10]`)}}</p>
            <p
              style="font-family: 'Inter';font-style: normal;margin:64px 0 8px 0;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.selectFile[3]`)}}
            </p>
            <div class="selectButton cursor">{{$t(`message.selectFile[1]`)}}</div>
            <div style="margin-top:32px">
              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="margin-top:-3px ;">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.65811 0.0256583C6.55548 -0.00855278 6.44452 -0.00855278 6.34189 0.0256583L0.341886 2.02566C0.137715 2.09372 0 2.28478 0 2.5V9.5C0 9.60554 0.0333988 9.70838 0.0954135 9.79379C0.286104 10.0564 0.470108 10.3171 0.651201 10.5737L0.651239 10.5737C1.27002 11.4504 1.85483 12.2789 2.5563 12.9713C3.49179 13.8947 4.64103 14.5911 6.38941 14.9876C6.46157 15.004 6.53646 15.0041 6.60868 14.988C9.8687 14.2621 11.3239 12.288 12.9222 9.7678C12.973 9.68771 13 9.59483 13 9.5V2.5C13 2.28478 12.8623 2.09372 12.6581 2.02566L6.65811 0.0256583ZM1 9.33811V2.86038L6.5 1.02705L12 2.86038V9.35452C10.479 11.7411 9.22029 13.3468 6.50088 13.9869C4.99483 13.6278 4.04041 13.0311 3.25878 12.2596C2.62646 11.6355 2.11047 10.9051 1.50375 10.0462L1.50369 10.0461C1.3434 9.81922 1.17677 9.58335 1 9.33811ZM9.89468 5.80696C10.0642 5.58899 10.0249 5.27485 9.80697 5.10532C9.589 4.93578 9.27486 4.97505 9.10532 5.19302L5.95305 9.24594L4.35355 7.64644C4.15829 7.45118 3.84171 7.45118 3.64645 7.64644C3.45118 7.8417 3.45118 8.15828 3.64645 8.35355L5.64645 10.3535C5.74786 10.455 5.88793 10.5079 6.03107 10.499C6.17421 10.4901 6.30663 10.4202 6.39468 10.307L9.89468 5.80696Z"
                  fill="#BBBBBC" />
              </svg>
              <span
                style="color: rgba(29, 29, 31, 0.3);margin-left:8px;font-family: 'Inter';font-style: normal;font-size: 16px;color: rgba(29, 29, 31, 0.3);">{{$t(`message.selectText[1]`)}}</span>
            </div>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
  <div id="drop-area" @drop="dropEvent" @dragenter.prevent @dragover.prevent class="fun-area-wrap border-left"
    style="width: 834px;" v-if="checkAppState() == 'operate'">
    <div class="grid-content" style="height: 507px;">
      <div class="pre-icon-wrap">
        <draggable v-model="fileList" group="people" @start="drag = true" @end="drag = false" item-key="id">
          <template #item="{element,index}">
            <div class="item-cell">
              <div class="pre-img-cell-wrap">
                <div class="pre-num">{{index+1}}</div>

                <!-- <div class="pre-img-back" v-if="element.preImg"
                  :style='{transform: "rotate("  +fileList[index].rotate + "deg)"}' @dblclick='magnifier(index)'>
                  <img v-if="element.preImg && fileList.length > 1"
                    :title="$t(`message.FunOperat.mergePDF.add.text[4]`)" class="pre-img-cell" :src="element.preImg"
                    alt="" @error="errImg(element,index)" @load="loadImage()" />
                  <img v-else class="pre-img-cell" :src="element.preImg" alt="" @error="errImg(element,index)"
                    @load="loadImage()" :style='{transform: "rotate("  +fileList[index].rotate + "deg)"}' />
                </div> -->
                <div class="pre-img-back" v-if="element.preImg && !element.fail"
                  :style='{transform: "rotate("  +fileList[index].rotate + "deg)"}' @dblclick='magnifier(index)'>
                  <img v-if="element.preImg && fileList.length > 1"
                    :title="$t(`message.FunOperat.mergePDF.add.text[4]`)" class="pre-img-cell" :src="element.preImg"
                    alt="" @error="errImg(element,index)" @load="loadImage()" />
                  <img v-else class="pre-img-cell" :src="element.preImg" alt="" @error="errImg(element,index)"
                    @load="loadImage()" />
                </div>
                <div class="pre-img-back" v-else-if="element.preImg && element.fail"
                  :style='{transform: "rotate("  +fileList[index].rotate + "deg)"}'>
                  <img v-if="element.preImg && fileList.length > 1"
                    :title="$t(`message.FunOperat.mergePDF.add.text[4]`)" class="pre-img-cell box-shaow"
                    :src="element.preImg" alt="" @error="errImg(element,index)" @load="loadImage()" />
                  <img v-else class="pre-img-cell box-shaow" :src="element.preImg" alt="" @error="errImg(element,index)"
                    @load="loadImage()" />
                </div>
                <div class="pre-img-back" v-else>
                  <img class="pre-img-cell" src="../../../assets/merge/loading.gif" alt=""
                    style="box-shadow: 0px 4px 12px rgba(33, 34, 42, 0.1);" />
                </div>
              </div>
              <!-- <div class="pre-img-cell-wrap1" v-if="!element.preImg"> -->
              <!-- <div class="pre-img-cell-wrap">
                <img class="pre-img-cell" src="../../../assets/merge/loading.gif" alt=""
                  :style='{transform: "rotate("  +fileList[index].rotate + "deg)"}' />
              </div> -->
              <div v-if="element.preImg && !element.fail" class="flex-justify-between d-flex hover-operation-area">
                <span class="turn-right text-white" @click="turnRight(index)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5 12C5 8.13689 8.14209 5 12.0245 5C15.3781 5 18.1806 7.34152 18.8806 10.4689L17.8325 10.5953C17.4746 10.6384 17.3196 11.0717 17.5688 11.3321L20.0566 13.9313C20.2583 14.142 20.6057 14.1001 20.7515 13.8475L22.5501 10.7315C22.7304 10.4193 22.4768 10.0353 22.1189 10.0784L20.874 10.2285C20.0482 6.10394 16.3987 3 12.0245 3C7.04328 3 3 7.02656 3 12C3 16.9734 7.04328 21 12.0245 21C14.445 21 16.6452 20.0483 18.2654 18.501C18.6648 18.1195 18.6794 17.4865 18.2979 17.0871C17.9165 16.6877 17.2835 16.6732 16.8841 17.0546C15.6216 18.2602 13.911 19 12.0245 19C8.14209 19 5 15.8631 5 12Z"
                      fill="white" />
                  </svg>
                </span>
                <span class="magnifier text-white" @click=" magnifier(index)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11ZM11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C12.8683 20 14.6036 19.4307 16.042 18.4562L20.2929 22.7071L21.7071 21.2929L17.5677 17.1535C19.0762 15.5441 20 13.3799 20 11C20 6.02944 15.9706 2 11 2ZM10 12H7V10H10V7H12V10H15V12H12V15H10V12Z"
                      fill="currentColor" />
                  </svg>
                </span>
                <span @click="removeSingleFile(index)" class="turn-left text-white">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7 5C7 3.34315 8.34315 2 10 2H14C15.6569 2 17 3.34315 17 5V6H20H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4H7V5ZM9 6H15V5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5V6ZM6 8V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V8H17H7H6ZM10 11C10.5523 11 11 11.4477 11 12V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V12C9 11.4477 9.44772 11 10 11ZM15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12Z"
                      fill="white" />
                  </svg>
                </span>
              </div>
              <div v-else-if="element.preImg" class="flex-justify-between d-flex hover-area">
                <span @click="removeSingleFile(index)" class="turn-left text-white">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7 5C7 3.34315 8.34315 2 10 2H14C15.6569 2 17 3.34315 17 5V6H20H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4H7V5ZM9 6H15V5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5V6ZM6 8V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V8H17H7H6ZM10 11C10.5523 11 11 11.4477 11 12V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V12C9 11.4477 9.44772 11 10 11ZM15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12Z"
                      fill="white" />
                  </svg>
                </span>
              </div>
              <div class="file-name-wrap" style="cursor:default" v-if="element.name.length < 16">
                <p>{{ element.name}}</p>
                <!-- <p>{{ element.name.substring(0, element.name.lastIndexOf(".")) }}</p>
                <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
              </div>
              <div class="file-name-wrap" style="cursor:default" :title="element.name" v-else>
                <!-- <p>{{
                  element.name.substring(0,7)}}...{{element.name.substring(element.name.lastIndexOf(".")-6,element.name.lastIndexOf("."))}}
                </p>
                <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
                <p>
                  {{
                  element.name.substring(0,5)}}...{{element.name.substring(element.name.length - 7)}}
                </p>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <hr style="background-color: grey; margin: 0" />
    <div style="display: flex;justify-content: space-between;height: 40px;">
      <div class="add-filelength">
        <span class="file-style">{{$t(`message.FunOperat.mergePDF.add.text[0]`)}}</span>
        <span class="img-length-wrap" :imglength="fileList.length">
          <span class="img-current-length">{{ fileList.length }}</span>
          <span class="img-current-length">/5</span>
        </span>
      </div>
      <div v-if="fileList.length < 5" class="uploadBox">
        <el-upload action="" :auto-upload="false" :show-file-list="false" multiple :on-change="clickUpload"
          :file-list="fileList" ref="my-upload" :accept="fileTypeUpload" :disabled="fileList.length == 5">
          <button id="add-img-btn" :disabled="fileList.length == 5">
            <img src="../../../assets/merge/add.svg" alt="" />
            <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          </button>
        </el-upload>
      </div>
      <div v-else class="uploadBox">
        <button id="add-img-btn1" :disabled="fileList.length == 5">
          <img src="../../../assets/merge/grey-add.svg" alt="" />
          <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          <span class="not-more-four">{{$t(`message.FunOperat.mergePDF.add.text[3]`)}}</span>
        </button>
      </div>
    </div>
  </div>
  <div style="height: 548px;background: #EAEAEA;width: 1px;" v-if="checkAppState() == 'operate'"></div>
  <div style="width:389px" class="action-area-wrap fun-area-wrap border-right" v-if="checkAppState() == 'operate'">
    <div class="add-img-wrap">
      <div>
        <h3 style='text-align: center;'>{{$t(`message.Navigation.pdf.info[10]`)}}</h3>
      </div>
      <p class="prompt-tit" v-if="fileList.length > 1">
        {{$t(`message.fileTopdf.JpgToPdf.text[3]`)}}
      </p>
    </div>
    <button id="btn-convert1" v-if="!canClick">
      {{$t(`message.UploadFun.title`)}}
    </button>
    <button id="btn-convert" @click=" getRoterFileList(false)" v-else>
      {{$t(`message.fileTopdf.button`)}}
    </button>
  </div>
  <!-- 转化中 -->
  <el-col class="fun-area-wrap fun-area-wrap-border" :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>

  <!-- 放大图片 -->
  <div class="jpg">
    <el-dialog v-model="magnifierFlag" width="fit-content" destroy-on-close center top="1vh">
      <img src="" alt="">
      <div
        style="width: 450px;height: 636px;overflow: auto; margin: 50px auto 0 auto;position:relative;display: flex;justify-content: center;align-items: center;">
        <img :src="fileList[magnifierNum].preImg" alt="" style="max-width: 100%; max-height: 100%"
          v-if="fileList[magnifierNum].preImg" />
        <img src="../../../assets/merge/loading.gif" alt="" style="max-width: 100%; max-height: 100%" v-else />
      </div>
      <div style="margin-top:30px" class="d-flex align-items-center justify-content-center position-relative">
        <div class="px-3 fw-normal small"
          style="text-align: center;height: 24px;background: rgba(0, 0, 0, 0.4);;border-radius: 100px;color: #fff;line-height: 24px;font-family: 'Inter'; font-style: normal;font-weight: 400;font-size: 16px;">
          <span>{{ magnifierNum + 1 }}</span>
          <span>/</span>
          <span>{{ fileList.length }}</span>
        </div>
      </div>
      <div class="magnifier-left" @click="changeMagnifierNum(true)" v-if='magnifierNum!=0'>
        <div class="magnifier-icon-left"></div>
      </div>
      <div class="magnifier-right" @click="changeMagnifierNum(false)" v-if='magnifierNum!=fileList.length-1'>
        <div class="magnifier-icon-right"></div>
      </div>
      <p
        style="margin-top:15px;font-family: 'Inter' ;font-style: normal;font-weight: 400;font-size: 16px; text-align:center;color: #FFFFFF;width: 450px;">
        {{ fileList[magnifierNum].name }}
      </p>
    </el-dialog>
  </div>

</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import {
    changeType,
    checkAppState,
    // removeSingleFile
  } from "../convert";
  import converting from "../../common/converting.vue";
  import draggable from "vuedraggable";
  import uploading from "../../common/uploading.vue";
  import "../convert.scss";
  import Tiff from 'tiff.js'
  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        targetType: "pdf",
        reqApi: "/app/wordConv",
        fileName: [],
        intoOneFlag: true,
        // fileList: "",
        convertedUrlList: [],
        downUrl: "",
        AddImage: 1,
        openPopFlag: true,
        gaStartime: "",
        magnifierFlag: false,
        magnifierNum: 0,
        rotateList: [],
        afterRotateList: [], //具体旋转角度
        afterRotateFileList: [], //旋转后文件列表
        fileList: [],
        fileTypeUpload: ".png,.jpg,.bmp,.tiff,.tif,.jpeg,.gif",
        pageName: "Images_to_PDF",
        currentFun: "jpgToPdf",
        canClick: false,
        fileSize: 0,//多文件总大小
        filelen: 0,//上传的文件数量
        failArray: [],
        failJPG: 0,//破损文件的数量
        failImgList: [],//破损的img数组放大展示
        taskId: '',
        operation: ''
      };
    },
    props: ["downFileType"],
    components: {
      // pdf,
      converting,
      draggable,
      uploading
    },
    methods: {
      changeType,
      checkAppState,
      // removeSingleFile,
      turnRight(num) {
        console.log("11111:", num);
        this.fileList[num].rotate += 90
      },
      dropEvent(e) {
        console.log("drope:", e);
        console.log("e.dataTransfer.files:", e.dataTransfer.files);
        e.stopPropagation()
        e.preventDefault()
        let _this = this
        e.dataTransfer.files.forEach((el) => {
          el.raw = el
          let obj = {}
          for (let i in el) {
            obj[i] = el[i]
          }
          _this.clickUpload(obj, null, true)
        })
      },
      magnifier(num) {
        this.magnifierFlag = true
        this.magnifierNum = num
      },
      removeSingleFile(fileNum) {
        if (this.fileList[fileNum].fail) this.failJPG--
        this.fileList.splice(fileNum, 1)
        this.fileList == 0 ? this.$store.commit("changeAppState", "start") : ''
        this.clickFlag = false
        this.$store.commit('changeUploadingFlag', true)
      },
      getRoterFileList(againFlag) {
        const _this = this
        let fail = this.fileList.filter((item) => item.fail)
        if (fail.length > 0) {
          _this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.UploadFun.message[5]`),
          })
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "file_error",
          });
          _this.$gtag.event("process_file", {
            event_category: _this.pageName,
            event_label: "fail",
          });
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          return
        }
        let uploadSucNum = 0
        let rotateSucNum = 0
        this.$store.commit("changeAppState", "converting");
        this.fileList.forEach(element => {
          let currRote = element.rotate % 360
          currRote < 0 ? _this.afterRotateList.push(currRote + 360) : currRote == 0 ? _this.afterRotateList.push(0) : _this.afterRotateList.push(currRote)
        });
        console.log("_this.afterRotateList:", _this.afterRotateList);
        let rotateHas = _this.afterRotateList.filter((item) => item > 0)
        if (rotateHas.length > 0) {
          _this.$gtag.event("click_convert", {
            event_category: _this.pageName,
            event_label: "use_rotate"
          });
        } else {
          _this.$gtag.event("click_convert", {
            event_category: _this.pageName,
            event_label: "no"
          });
        }
        _this.afterRotateList.forEach((ele, index) => {
          if (againFlag) {
            const num = index;
            const uploadFile = this.fileList[num].document;
            this.$http
              .post("/app/uploadFile", {
                token: this.$store.state.pdfToken,
                "files[]": uploadFile,
              })
              //upload file and check progress in ws
              .then((response) => {
                if (response.code == 200) {
                  _this.uploadState = "1";
                  _this.fileList[num]["fileUrl"] = response.data.response_file[0];
                  uploadSucNum++
                  if (uploadSucNum == _this.afterRotateList.length) {
                    _this.afterRotateList.forEach((ele, index) => {
                      if (ele == 0) {
                        _this.afterRotateFileList[index] = _this.fileList[index].fileUrl
                        rotateSucNum++
                        if (rotateSucNum == _this.afterRotateFileList.length) {
                          _this.convertImgToPdf()
                        }
                      } else {
                        _this.$http
                          .post("/app/picrotate", {
                            token: _this.$store.state.pdfToken,
                            "file_url": _this.fileList[index].fileUrl,
                            'angle': ele,
                            'format': 'png'
                          }).then(res => {
                            if (res.code == 200) {
                              _this.afterRotateFileList[index] = res.data.result
                              rotateSucNum++
                              if (rotateSucNum == _this.afterRotateFileList.length) {
                                _this.convertImgToPdf()
                              }
                            } else {
                              rotateSucNum++
                              if (rotateSucNum == _this.afterRotateFileList.length) {
                                _this.convertImgToPdf()
                              }
                            }
                          }).catch(err => {
                            ////console.log(err)
                            uploadSucNum++
                            if (uploadSucNum == _this.afterRotateList.length) {
                              _this.converWrongPop();

                            }

                          })
                      }
                    })
                  }
                } else {
                  uploadSucNum++
                  if (uploadSucNum == _this.afterRotateList.length) {
                    _this.converWrongPop();

                  }
                }
              })
              .catch((err) => {
                uploadSucNum++
                if (uploadSucNum == _this.afterRotateList.length) {
                  _this.converWrongPop();

                }

                ////console.log(err);
              });
          } else {
            if (ele == 0) {
              _this.afterRotateFileList[index] = _this.fileList[index].fileUrl
              rotateSucNum++
              if (rotateSucNum == _this.afterRotateFileList.length) {
                _this.convertImgToPdf()
              }
            } else {
              _this.$http
                .post("/app/picrotate", {
                  token: _this.$store.state.pdfToken,
                  "file_url": _this.fileList[index].fileUrl,
                  'angle': ele,
                  'format': 'png'
                }).then(res => {
                  if (res.code == 200) {
                    _this.afterRotateFileList[index] = res.data.result

                    rotateSucNum++

                    if (rotateSucNum == _this.afterRotateFileList.length) {
                      _this.convertImgToPdf()
                    }
                  } else {
                    rotateSucNum++
                    if (rotateSucNum == _this.afterRotateFileList.length) {
                      _this.converWrongPop();
                    }
                  }
                }).catch(err => {
                  let converTime =
                    Math.floor(
                      ((new Date().getTime() - _this.gaStartime) / 1000) * 100
                    ) / 100;

                  rotateSucNum++
                  if (rotateSucNum == _this.afterRotateFileList.length) {
                    _this.converWrongPop();
                  }


                })
            }
          }
        })

      },
      loadImage() {
        console.log("loadImage");
        return true;
      },
      changeMagnifierNum(cutFlag) {
        if (cutFlag) {
          this.magnifierNum--
        } else {
          this.magnifierNum++
        }
      },
      errImg(item, index) {
        console.log("err:", this.fileList[index]);
        this.fileList[index].preImg = require('../../../assets/convert/failpng1.png')
        this.fileList[index].fail = true
      },
      renderImg(index) {
        var reader = new FileReader();
        const _this = this;
        console.log("this.fileList[index].document:", this.fileList[index]);
        const fileTypeT = this.fileList[index].name.toLowerCase().substr(this.fileList[index].name.lastIndexOf(".") + 1);
        reader.readAsDataURL(this.fileList[index].document);
        reader.onload = function () {
          if (fileTypeT == 'tif' || fileTypeT == 'tiff') {
            let fr = new FileReader();
            fr.readAsArrayBuffer(_this.fileList[index].raw);
            fr.onload = function (e) {
              console.log("-=-=-=-=-=-=-=-=");
              try {
                let url = new Tiff({ buffer: e.target.result });
                _this.fileList[index].preImg = url.toDataURL();
              } catch (e) {
                _this.fileList[index].preImg = require('../../../assets/convert/img-normal.svg')
                console.log("1111111111111111111111111111111:", url);
              }

            }
          } else {
            _this.fileList[index].preImg = reader.result

          }
        };
        reader.onerror = function () {
          // _this.fileList[index].fail = true
          // console.log("onerror:");
          // _this.fileList[index].preImg = require('../../../assets/merge/failfiles.svg')

        };
      },
      changeIntoOne() {
        this.intoOneFlag = !this.intoOneFlag;
      },
      changeClickOrDrag() {
        this.clickUploadFlag = true
      },
      checkFile(fileMsg) {
        ////console.log("检测文件：", this.pageId);
        // let gaStartime = new Date().getTime()
        let _this = this
        return new Promise((res, rej) => {
          _this.fileType = fileMsg.name.toLowerCase().substr(fileMsg.name.lastIndexOf(".") + 1);
          console.log("_this.fileType:", _this.fileType);
          if (_this.fileType != 'jpg' && _this.fileType != 'png' && _this.fileType != 'bmp' && _this.fileType != 'tiff' && _this.fileType != 'gif' && _this.fileType != 'jpeg' && _this.fileType != 'tif') {
            console.log("选错了13：", _this.fileType);
            _this.$message({
              type: "error",
              showClose: true,
              duration: 8000,
              message: _this.$t(`message.commonTip.alertTip[1].poptext`),
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "file_error",
            });
            _this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            // this.fileList = []
            rej();
            return
          }
          if (fileMsg.size > 30 * 1024 * 1024) {
            this.$message({
              type: "error",
              showClose: true,
              duration: 8000,
              message: _this.$t(`message.UploadFun.message[0]`),
            });
            _this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            if (_this.operation == 'Windows') {
              _this.$store.commit('changeIsShowPur', true)
            }
            rej();
            return
          } else if (_this.fileSize + fileMsg.size > 100 * 1024 * 1024) {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: _this.$t(`message.UploadFun.message[0]`),
            });
            _this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            rej();
            return
          }
          _this.fileSize += fileMsg.size
          _this.fileList.push(fileMsg)
          //console.log("_this.fileList:", _this.fileList);
          res();
          // return true
        });
      },
      //上传调用
      clickUpload(fileMsg, ComefileList, name) {
        const _this = this
        console.log("fileMsg:", fileMsg, _this.canClick);

        this.filelen = this.filelen + 1
        this.$gtag.event("upload", {
          event_category: _this.pageName,
          event_label: "null",
        });
        if (this.checkAppState() == "operate") {
          _this.$gtag.event("add_files", {
            event_category: _this.pageName,
            event_label: "null",
          });
        }
        if (this.fileList.length >= 5) {
          this.filelen--
          if (this.operation == 'Windows') {
            this.$store.commit('changeIsShowPur', true)
          }
          return
        }
        let length = _this.fileList.length
        this.checkFile(fileMsg, ComefileList).then(function name() {
          _this.uploadToServerProgress(length);
          _this.canClick = false

        }).catch((e) => {
          _this.filelen--
          if (_this.filelen == 0) {
            _this.btnloading = false
          }
        })
        console.log("_this.filelen:", _this.filelen);

      },

      //上传文件并返回文件路径
      uploadToServerProgress(index) {
        // let gaStartime = new Date().getTime();
        this.uploadState = '2'
        const _this = this
        // const num = this.fileList.length - 1
        // console.log("uploadToServerProgress:", index, _this);
        // this.reTry(3, "/app/uploadFile", 1111, index, _this);

        // const uploadFile = this.fileList[num].document
        const event = event || window.event;
        const file = this.fileList[index].raw ? this.fileList[index].raw : this.fileList[index];
        this.fileList[index]["document"] = file;
        this.fileList[index]['rotate'] = 0
        this.$store.commit("changeAppState", "operate");
        const uploadFile = this.fileList[index].document;
        // setTimeout(() => {
        // this.renderImg(index);
        // }, 2000)
        // console.log("this.fileList[index]:", this.fileList[index]);
        this.reTryUpload(3, "/app/uploadFile", uploadFile, index, _this);
      },

      //重试
      reTryUpload(times, api, url, num, _this) {
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, api, url, num, _this) {
            // //console.log(`第${times}次重试`);
            _this.$http
              .post(api, {
                token: _this.$store.state.pdfToken,
                'files[]': url,
                task_id: _this.taskId
              }).then((res) => {
                if (res.code == 200) {
                  _this.failArray.push(1)
                  _this.uploadState = "1";
                  _this.renderImg(num);
                  _this.fileList.length > 0 ? (_this.fileList[num]["fileUrl"] = res.data.response_file[0]) : null;
                } else {
                  _this.uploadState = '0'
                }
                resolve(res)
              }).catch((err) => {
                //console.log("err:", err);
                if (times > 0) {
                  setTimeout(() => {
                    reTryFunc(times - 1, api, url, num, _this)
                  })
                } else {
                  _this.uploadState = '0'
                  _this.failArray.push(2)
                  reject(err)
                }
              }).finally(() => {
                console.log("finally:", _this.failArray.length, _this.filelen);
                //当请求次数和文件数量相同时在进行操作
                if (_this.failArray.length == _this.filelen) {

                  setTimeout(() => {
                    console.log(11112222);
                    _this.canClick = true
                    let fail = _this.failArray.filter((item) => item == 2) //失败的数量
                    let successArray = _this.failArray.filter((item) => item == 1) //成功的数量
                    let num = _this.failArray
                    console.log("fileLastArray:", _this.fileList);
                    let fail111 = _this.fileList.filter((item) => item.fail == true)
                    let failLength = fail111.length - _this.failJPG //总共的损坏文件删掉之前的破损文件
                    console.log("fai1111l:", fail111.length, _this.failJPG);
                    _this.failJPG = fail111.length
                    // console.log("fai1111l:", fail111);
                    let len1 = _this.fileList.length
                    let len2 = _this.failArray.length
                    let len3 = len1 - len2 //原先数组的长度
                    let size = 0
                    //console.log("len1:", len1, len2, len3);
                    //失败的需要从数组中删除掉，不然会显示空白在页面上
                    _this.failArray.forEach((item, index) => {
                      //console.log("item:", _this.fileList[index + len3]);
                      if (_this.fileList[index + len3]) {
                        //console.log("size:", _this.fileList[len3 + index].size);
                        size += _this.fileList[len3 + index].size
                      }
                      if (item == 2) {
                        _this.fileList.forEach((item, index) => {
                          _this.fileList.splice(len3 + index, 1)
                        })
                      }
                    })
                    //如果失败的长度和文件上传的长度一致则弹窗，部分成功不弹窗
                    console.log("fail.length == _this.filelen:", fail.length, _this.filelen);
                    if (fail.length == _this.filelen) {
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      })
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "network_error",
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "fail",
                      });
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "0.1",
                      });
                    } else {
                      //成功或者部分成功
                      if (successArray.length != _this.filelen) {
                        _this.$message({
                          showClose: true,
                          duration: 8000,
                          type: "error",
                          message: _this.$t(`message.UploadFun.message[3]`),
                        })
                      }
                      let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: "null",
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${updateTime}-${(size / 1024 / 1024).toFixed(3)}`,
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "success",
                      });
                      _this.$gtag.event("upload_size", {
                        event_category: _this.pageName,
                        event_label: (size / 1024 / 1024).toFixed(3)
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${_this.filelen}-${failLength}`,
                      });
                    }
                    if (_this.fileList.length == 0) {
                      _this.$store.commit("changeAppState", "failUpload");
                    }
                    _this.failArray = []
                    _this.filelen = 0

                  }, 1000)
                }
              })
          }
          reTryFunc(times, api, url, num, _this)
        })
      },

      //转化pdf
      convertImgToPdf() {
        const _this = this;
        this.gaStartime = new Date().getTime();
        this.intoOneFlag ?
          this.$emit("update:downFileType", "pdf") :
          this.$emit("update:downFileType", "zip");

        let formData = new FormData();
        formData.append("token", _this.$store.state.pdfToken);
        for (let i = 0; i < _this.afterRotateFileList.length; i++) {
          formData.append("file_url[]", _this.afterRotateFileList[i]);
        }
        formData.append("conv", "pdf");
        this.intoOneFlag == true ?
          formData.append("type", "1") :
          formData.append("type", "2");

        this.$http
          .post("/app/imgConv", formData)
          .then((Response) => {
            let converImgNumArr = Response.data.result;
            if (_this.intoOneFlag) {
              this.$http
                .ws(_this.$store.state.pdfToken, converImgNumArr[0].number)
                .then((response) => {
                  if (response.data.status == 1 && _this.$store.state.appState == 'converting') {
                    ////console.log("传输成功imgtoPDF");
                    _this.convertedUrlList.push(response.data.output_files[0]);
                    _this.downUrl = response.data.output_files[0];
                    console.log("_this.downUrl:", _this.downUrl);
                    _this.$emit("update:downUrl", _this.downUrl);
                    _this.$store.commit("changeAppState", "converted");
                    let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
                    _this.$gtag.event("process_success", {
                      event_category: _this.pageName,
                      event_label: `${covertTime}-${(_this.fileSize / 1024 / 1024).toFixed(3)}`
                    });
                    _this.$gtag.event("process_file", {
                      event_category: _this.pageName,
                      event_label: "success",
                    });
                    _this.$gtag.event("process_success", {
                      event_category: _this.pageName,
                      event_label: _this.fileList.length
                    });
                  }

                })
                .catch((response) => {
                  ////console.log("转化失败了webscoket");
                  let converTime =
                    Math.floor(
                      ((new Date().getTime() - _this.gaStartime) / 1000) * 100
                    ) / 100;
                  this.converWrongPop();
                });
            } else {
              for (let i = 0; i < converImgNumArr.length; i++) {
                this.$http
                  .ws(_this.$store.state.pdfToken, converImgNumArr[i].number)
                  .then((response) => {
                    _this.convertedUrlList.push(response.data.output_files[0]);
                  })
                  .catch((response) => {
                    let converTime =
                      Math.floor(
                        ((new Date().getTime() - _this.gaStartime) / 1000) * 100
                      ) / 100;
                  });
              }
            }
          })
          .catch((err) => {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: "The network is abnormal. Please check the network connection.",
            });
            _this.$gtag.event("process_fail", {
              event_category: _this.pageName,
              event_label: "network_error",
            });
            let covertTime = ((new Date().getTime() - _this.gaStartime) / 1000).toFixed(1)
            _this.$gtag.event("process_fail", {
              event_category: _this.pageName,
              event_label: covertTime,
            });
            _this.$gtag.event("process_file", {
              event_category: _this.pageName,
              event_label: 'fail',
            });
            _this.$store.commit("changeAppState", "failConvert");

          });
      },
      comprePdf() {
        const _this = this;
        let formData = new FormData();
        formData.append("token", _this.$store.state.pdfToken);
        formData.append("task_id", this.taskId);
        for (let i = 0; i < _this.convertedUrlList.length; i++) {
          formData.append("file_url[]", _this.convertedUrlList[i]);
        }
        _this.$http
          .post("/app/compressFile", formData)
          .then((Response) => {
            if (Response.code == 200) {
              _this.downUrl = Response.data.result;
              this.$emit("update:downUrl", _this.downUrl);
              this.$store.commit("changeAppState", "converted");
            } else {
              _this.converWrongPop();
            }
          })
          .catch((err) => {
            _this.converWrongPop();
          });
      },
      //转化失败
      converWrongPop() {
        const _this = this;
        _this.afterRotateList = []
        // _this
        //   .$confirm(
        //     "<p class='pop-tit'>" + _this.$t(`message.convertConfirm.error`) + "</p><p class='pop-text'>" + _this.$t(`message.convertConfirm.title`) + "</p>", "", {
        //     confirmButtonText: _this.$t(`message.convertConfirm.confirmButtonText`),
        //     cancelButtonText: _this.$t(`message.convertConfirm.cancelButtonText`),
        //     confirmButtonClass: "confirmBtn",
        //     cancelButtonClass: "cancleBtn",
        //     customClass: "openPop-btn-group",
        //     dangerouslyUseHTMLString: true,
        //   }
        //   )
        //   .then(() => {
        //     _this.getRoterFileList(true);
        //   })
        //   .catch(() => {
        //     window.location.reload();
        //   });
      },
    },
    watch: {
      fileList: {
        handler(val) {
          console.log("watch:", val);
          this.fileList = val;
          this.afterRotateFileList.length = val.length
          this.failImgList = this.fileList.filter((item) => !item.fail && item.preImg)
          this.$emit("update:fileList", val);
          // if (val.length > 0) {
          //   this.renderImg();
          // }
        },
        deep: true,
      },
      convertedUrlList: {
        handler(val) {
          if (val.length == this.fileList.length && !this.intoOneFlag) {
            this.comprePdf();
          }
        },
        deep: true,
      },
    },
    mounted() {
      this.operation = sessionStorage.getItem('getOS');
      this.$http
        .get("/app/user/get-taskid").then((res) => {
          this.taskId = res.data.task_id
          console.log("res.data:", res.data);
        })
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-upload {
    &:focus {
      color: black;
      /* border-color: red */
    }
  }

  .fun-area-wrap {
    height: 548px;
    // overflow: auto;
    background: #fff;
    border-radius: 16px;
  }

  .uploadBox {
    margin: 6px 8px;
    width: 110px;

    ::v-deep .el-upload,
    ::v-deep .el-upload-dragger {
      width: 110px !important;
      height: 28px;

    }
  }

  .hover-area {
    background: rgba(27, 33, 39, 0.3);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 35px;
    padding: 2px 6px;
    height: 32px;
    width: 45px;
    background: rgba(27, 33, 39, 0.5);
    border-radius: 16px;
    opacity: 0;

    span:hover {
      cursor: pointer;
      /* color: #DBEAFD !important; */

    }

    .turn-left {
      margin: 0 auto;
      position: relative;
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;

      }
    }
  }

  .dotteBox {
    padding: 24px;
    border-radius: 16px;
  }

  .border-left {
    border-radius: 16px 0 0 16px;
  }

  .border-right {
    border-radius: 0 16px 16px 0;
  }

  .grid-content {
    // width:calc(100% - 4px)
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f5f5f5 #fff;
    scrollbar-width: thin;

    &:hover::-webkit-scrollbar {
      /* display: block */
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 16px;
      background-color: #f5f5f5;
      /* display: none; */
    }

    /*定义滚动条轨道
 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    /*定义滑块
 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: rgba(0, 22, 56, 0.1);
    }

  }

  .fun-area-wrap .pre-icon-wrap {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 32px 2px 32px 40px;

    .pre-icon-img-wrap {
      height: 212px;
    }

    .item-cell {
      display: inline-block;

      img {
        max-width: 212px;
        max-height: 212px;
      }

      &:hover {
        background: #F5F7FD;
        border-radius: 8px;

        .remove-single-file {
          display: block;

          &:hover {
            .remove-icon {
              display: none;
            }

            .remove-icon.hover {
              display: block;
            }
          }
        }

        .hover-operation-area {
          opacity: 1;
        }

        .hover-area {
          opacity: 1;
        }
      }

      .remove-single-file {
        position: absolute;
        right: 14px;
        top: 10px;
        display: none;

        &:hover {
          cursor: pointer;
        }

        &:active {
          transform: translate(1px, 1px);
        }

        .remove-icon.hover {
          display: none;
          transform: translate(-1px, 1px);
        }
      }
    }
  }

  .action-area-wrap {
    padding: 24px 32px;
  }

  .item-cell {
    text-align: center;
    width: 232px;
    margin: 0 29px 32px 0;

    /* margin-left: 26px;
    margin-top: 16px;
    padding-top: 24px; */
    position: relative;

    .pre-img-cell-warp1 {
      height: 236px;
      width: 232px;
      text-align: center;

      .pre-img-cell {
        max-width: 150px !important;
        max-height: 212px;
        position: relative;
        /* width: 150px;
        height: 212px; */
        /* margin-top: 9px; */
      }
    }

    .pre-img-cell-wrap {
      height: 236px;
      width: 232px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .pre-img-back {
        background: #fff;
        width: 150px;
        display: flex;
        height: 212px;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }

      .pre-num {
        width: 24px;
        height: 24px;
        border-radius: 50px;
        color: white;
        position: absolute;
        left: 28px;
        top: 10px;
        z-index: 10;
        background: rgba(29, 29, 31, 0.3);
      }

      .pre-img-cell {
        max-width: 150px !important;
        max-height: 212px;
        position: relative;
        /* width: 150px;
        height: 212px; */
        /* margin-top: 9px; */
      }

    }
  }

  .pre-img-cell {
    .pre-icon {
      max-width: 212px;
      max-height: 212px;
      width: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .check-one-wrap {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    font-size: 14px;
    line-height: 18px;
    color: #001637;

    .check-icon {
      display: inline-block;
      border: 1px solid #278bff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
      transform: translateY(2px);
      position: relative;

      .checked-icon {
        display: inline-block;
        background: #278bff;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        top: 50%;
      }
    }
  }

  .pre-pdf-wrap .pre-pdf-content {
    box-shadow: none;
  }

  /* .fun-area-wrap .pre-icon-wrap .hover-tit {
    top: calc(100% - 32px);
  } */

  .hover-operation-area {
    background: rgba(27, 33, 39, 0.3);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 44px;
    padding: 2px 6px;
    height: 32px;
    /* width: 120px;
    background: rgba(27, 33, 39, 0.5);
    border-radius: 16px; */
    opacity: 0;
    width: 108px;
    flex: 1;
    background: rgba(29, 29, 31, 0.5);
    border-radius: 16px;

    span:hover {
      cursor: pointer;
      /* color: #DBEAFD !important; */
    }

    .turn-right {
      /* margin-left: 12px;
      margin-right: 6px; */
      position: relative;
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }

    .turn-left {
      opacity: 0.6;
      /* margin-right: 6px; */

      &:hover {
        cursor: pointer;
        opacity: 1;

      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }

    .magnifier {
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .file-name-wrap {
    display: flex;
    max-width: 150px;
    margin: 12px auto 6px auto;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 22px;
    color: rgba(29, 29, 31, 0.7);

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }
  }

  .magnifier:hover {
    cursor: pointer;
  }

  .magnifier-left {
    position: absolute;
    top: 400px;
    /* top: calc(50% - 28px); */
    left: -80px;
    background: rgba(255, 255, 255, 0.2);
    /* box-shadow: 0px 25.6px 76.8px rgba(41, 46, 76, 0.1); */
    padding: 20px;
    border-radius: 50%;
    width: 64px;
    height: 64px;

    &:hover {
      /* background: rgba(0, 22, 55, 0.5); */
      background: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }

    .magnifier-icon-left {
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      border-bottom: none;
      border-right: none;
      /* transform: translateX(2px) rotate(-45deg); */
      transform: translateX(5px) translateY(5px) rotate(-45deg);
    }
  }

  .magnifier-right {
    position: absolute;
    top: 400px;
    right: -80px;
    background: rgba(255, 255, 255, 0.2);
    /* box-shadow: 0px 25.6px 76.8px rgba(41, 46, 76, 0.1); */
    padding: 20px;
    border-radius: 50%;
    width: 64px;
    height: 64px;

    &:hover {
      /* background: rgba(0, 22, 55, 0.5); */
      background: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }

    .magnifier-icon-right {
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      border-bottom: none;
      border-right: none;
      /* transform: translateX(-2px) rotate(-225deg); */
      transform: translateX(0px) translateY(5px) rotate(-225deg);
    }
  }

  .box-shaow {
    box-shadow: 0px 3.39623px 10px rgba(33, 34, 42, 0.1);
  }
</style>
<style>
  .jpg .el-dialog {
    background: none;
    box-shadow: none;
  }

  .jpg .el-dialog--center .el-dialog__body {
    width: 100%;
  }

  .jpg .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #fff;
  }

  .jpg .el-dialog__body {
    width: 450px;
  }

  .jpg .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    position: relative;
    top: 55px;
    left: 30px;
    font-size: 32px;
  }
</style>