<template lang="">
  <el-col :span="24" class="bg-white" v-if="checkAppState() == 'converted'">
    <div class="fileUpload">
      <div class="fileUpload-Box">
        <div id="down-pre-area">
          <div class="pre-icon-wrap">
            <!-- 只有pdf才出现缩略图 -->
            <img class="pre-icon" :src="$store.state.downloadPdfImg" alt="" />
            <!-- <img class="pre-icon" :src="downFileOffImg" alt="" v-if="occupancyFlag" /> -->
            <canvas v-for="page in pages" :id="'canvas' + page" :key="page"></canvas>
            <!-- <img class="success-icon" src="../../assets/convert/success.svg" alt="" /> -->
            <img class="success-icon" src="../../assets/download/successcir.png" alt="" />
            <!-- <img class="right-icon" src="../assets//merge/filebg.svg" alt="" /> -->
            <img class="right-icon" :src="downFileOffImg" alt="" />
          </div>
          <div class="down-name-wrap cursor">
            <p id="file-name" v-if="downFileName.length < 15">
              {{ downFileName.substring(0, downFileName.lastIndexOf(".")) }}.{{ downFileType }}
            </p>
            <p id="file-name" v-else>
              {{ downFileName.substring(0,
              8)}}...{{downFileName.substring(downFileName.lastIndexOf(".")-4,downFileName.lastIndexOf("."))}}.{{
              downFileType }}
            </p>
            <p id="file-name-hover" v-if="downFileName.length < 50">{{ downFileName }}</p>
            <p id="file-name-hover" v-else>{{ downFileName.substring(0,40)
              }}...{{downFileName.substring(downFileName.length - 40)}}</p>
          </div>
        </div>
        <div class="box-right">
          <h3>{{$t(`message.FileProcessingSucceeded`)}}</h3>
          <p class="boxp"><span>{{$t(`message.downloadIntro1`)}} </span> <span class="purspan"
              @click="openFloder">{{$t(`message.downloadIntro2`)}}</span>
          </p>
          <div class="box-button">
            <div class="box-right-DownButton cursor" style="margin-right: 20px;" @click="clickDown(1)">
              <span>{{$t(`message.DownloadAgain`)}}</span>
            </div>
            <div class="box-right-DownButton cursor" @click="retry">
              <span>{{$t(`message.Restart`)}}</span>
            </div>
          </div>
          <div class="tip">
            <p class="tipTop">{{$t(`message.Tip`)}}</p>
            <p class="tipP">{{$t(`message.Tip1`)}}</p>
            <p class="tipP">{{$t(`message.Tip2`)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttomDiv"
      v-if="nowTime >= $store.state.userInfo.vip_expired_time || !$store.state.userInfo.vip_expired_time">
      <span>{{$t(`message.RemainingFree`)}}（{{$store.state.optCount.remaining
        -1 }}），<span class="purspan" @click="goPur">{{$t(`message.RemainingFree1`)}}</span>
        {{$t(`message.RemainingFree2`)}}</span>
    </div>
  </el-col>
  <div class="appDia">
    <el-dialog v-model="downloadFail" width="50%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="apptip">
        <p class="apptipTop">{{$t(`message.DownloadErrorToast`)}}</p>
        <p class="apptipP">{{$t(`message.DownloadErrorToast1`)}}</p>
        <p class="apptipP">{{$t(`message.DownloadErrorToast2`)}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="downloadFail = false">{{$t(`message.OK`)}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { openPop } from "../../api/common";
import _ from "lodash"
import PDF from "pdfjs-dist";
import { gaEvent } from "@/utils/gaEvent";
PDF.disableWorker = true;
export default {
  data() {
    return {
      downUrl: this.$parent.downInof.downUrl,
      downFileType: this.$parent.downInof.downFileType,
      downflag: this.$parent.downInof.downflag,
      downFileName: "", //can not use this.downFileType ,updata this data in mounted
      downloadingFlag: false,
      fileInfo: this.fileList,
      downFileOffName: "",
      downFileOffImg:
        require("../../assets/download/word-file.svg"),
      successText: "Your file is ready",
      gaCurrentCat: "",
      pages: 0,
      occupancyFlag: true,
      enterComTime: '',
      isDown: false,
      btnloading: false,
      comName: "pdnob_converted",
      downSavePath: "D:\\Users\\Documents\\Afirstsoft\\",
      nowTime: '',
      downloadFail: false
    };
  },
  props: ["fileList"],
  methods: {
    retry() {
      gaEvent("restart", {
        event_category: this.$parent.pageName,
        event_label: "succeed",
      });
      window.location.reload()
    },
    goPur() {
      window.open("https://www.pdnob.com/purchase/buy-afirstsoft.html")
      gaEvent("click_buy", {
        event_category: "Buy",
        event_label: this.$store.state.currentPage,
      });
    },
    gopath() {
      window.open("https://cbs.pdnob.com/go?pid=5808&a=dp")
    },
    checkAppState() {
      return this.$store.state.appState;
    },
    handleClose(done) {
      this.downloadFail = false
    },
    downloadUrlFile(url, fileName, path) {
      console.log("downloadUrlFile:", url, fileName);
      const _this = this
      let time = Math.floor(new Date().valueOf() / 1000)
      let fileName1 = fileName.split('.')
      let reayfileName = ''
      fileName1.forEach((element, item) => {
        if (item == (fileName1.length - 1)) {
          reayfileName = reayfileName + time + '.' + fileName1[fileName1.length - 1]
        } else {
          reayfileName = reayfileName + element + '.'

        }
      });
      console.log("reayfileName:", reayfileName, time);
      // let reayfileName = fileName1[0] + time + '.' + fileName1[1]
      if (path) {
        console.log("默认下载", url, reayfileName, path);
        electron.ipcRenderer.send('download', {
          // downloadPath: 'https://pdnob-test-api.afirstsoft.cn/static/16686656496wr/download/pdnob_converted_2a783d55d8fb76c11b1f997f0c2ac7b1.docx', // 下载链接（以下载vue文件为例）
          downloadPath: url, // 下载链接（以下载vue文件为例）
          fileName: reayfileName, // 下载文件名，需要包含后缀名
          savedPath: path // 下载文件名，需要包含后缀名
        })
      } else {
        console.log("另存为下载", url, reayfileName);
        electron.ipcRenderer.send('download', {
          // downloadPath: 'https://pdnob-test-api.afirstsoft.cn/static/16686656496wr/download/pdnob_converted_2a783d55d8fb76c11b1f997f0c2ac7b1.docx', // 下载链接（以下载vue文件为例）
          downloadPath: url, // 下载链接（以下载vue文件为例）
          fileName: reayfileName, // 下载文件名，需要包含后缀名
        })
        electron.ipcRenderer.on('downloadPath', (event, arg) => {
          if (arg) {
            let a = arg
            a = a.split('\\')
            a.splice(a.length - 1, 1)
            let b = a.join("\\")
            console.log('保存的地址:', b)
            localStorage.setItem("savePath", b + '\\')
          }

        })
      }
      // debugger
      // let a = _.debounce(() => {
      //   electron.ipcRenderer.on('downloadState', (event, arg) => {
      //     console.log('渲染进程收到的消息:', arg, new Date().getTime())
      //     if (arg) {
      //       this.downloadFail = true
      //       // this.$message.error('The file download failed, please click Save As to download again.')
      //       _this.downloadFailGa()
      //       console.log("下载失败:", arg);
      //     }
      //   })
      // }, 1000)
      // a()
    },

    openFloder() {
      // let savePath = localStorage.getItem("savePath") ? localStorage.getItem("savePath") : this.downSavePath
      gaEvent("click_filepath", {
        event_category: this.$parent.pageName,
        event_label: "null"
      });
      electron.ipcRenderer.send('openFloder', this.downSavePath)

    },

    saveAs(data, name) {
      const urlObject = window.URL || window.webkitURL || window;
      const export_blob = new Blob([data]);
      const save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = urlObject.createObjectURL(export_blob);
      save_link.download = name;
      save_link.click();
    },
    downloadFailGa() {
      console.log("909090909090");
      gaEvent("download_fail", {
        event_category: this.$parent.pageName,
        event_label: "null"
      });
    },
    debounce(fn) {
      let timer = null
      const _this = this
      console.log("1121212:", fn);
      return function () {
        console.log("??????????");
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          console.log("有这么快吗");
          fn.apply(this, arguments);
          timer = null;
        }, 5000);
      }
    },
    clickDown(index) {
      this.$message({
        showClose: true,
        duration: 8000,
        type: "success",
        message: this.$t(`message.DownloadToast`),
      })
      if (index) {
        gaEvent("click_redownload", {
          event_category: this.$parent.pageName,
          event_label: "null"
        });
      }
      this.downloadingFlag = true;
      this.btnloading = true
      // this.downUrl = 'http://' + this.downUrl.split('//')[1]
      this.downloadUrlFile(this.downUrl, this.downFileName, this.downSavePath);
      this.$emit("update:downflag", true);
      this.$store.commit('changeDown', 1)
    },
    reloadPage() {
      this.downflag;
      const _this = this;
      // //console.log(_this.$route);
      gaEvent("download_restart", {
        event_category: _this.$parent.pageName,
        event_label: "null",
      });
      if (_this.downflag) {
        window.location.href = _this.$route.fullPath;
      } else {
        gaEvent("no_download", {
          event_category: _this.$parent.pageName,
          event_label: "null",
        });
        // //console.log("reloadPageElse");
        this.$confirm(this.$t(`message.commonTip.alertTip[6].poptext`), {
          confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
          cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
          type: "none",
          closeOnClickModal: false
        })
          .then(() => {
            window.location.href = _this.$route.fullPath;
            gaEvent("no_download_leave", {
              event_category: _this.$parent.pageName,
              event_label: "null",
            });
          })
          .catch(() => {
          });

      }
    },
    loadFile(url) {
      PDF.getDocument(url)
        .then((pdf) => {
          // //console.log("enter loadFile");
          this.pdfDoc = pdf;
          this.pages = this.pdfDoc.numPages;
          this.$nextTick(() => {
            this.renderPage(1);
          });
        })
        .catch(function (err) {
          // //console.log(err);
          return false;
        });
    },
    renderPage(num) {
      // //console.log("renderPage");
      this.pdfDoc.getPage(num).then((page) => {
        // const __this = this;
        let canvas = document.getElementById("canvas" + num);
        let ctx = canvas.getContext("2d");
        let dpr = 1; // 设备像素比
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1; // 浏览器在渲染canvas之前会用几个像素来来存储画布信息,类似img
        let ratio = dpr / bsr;
        let viewport = page.getViewport(1.5);
        canvas.width = viewport.width * ratio; // 画布大小,默认值是width:300px,height:150px
        canvas.height = viewport.height * ratio;
        canvas.style.width = viewport.width + "px"; // 画布的框架大小
        canvas.style.height = viewport.height + "px";
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        // //console.log("---=======------", renderContext.toDataURL('image/jpg'));
        var renderTask = page.render(renderContext);
        renderTask.promise
          .then(function () {
            document.querySelector(".pre-icon").src = document.querySelectorAll("#down-pre-area canvas")[0].toDataURL("image/jpg");
            console.log("Page rendered:", document.querySelector(".pre-icon").src);

            document.querySelector(".pre-icon-wrap").classList.add('pre-icon-conner')
            // document.querySelector(".right-icon").style.display = 'block'
            document.querySelector(".pre-icon").style.opacity = '1'

          })
          .catch(function (err) {
            // //console.log(err);
          });
      });
    },
    goPath(url, name) {
      let _this = this
      // //console.log("_this.$parent:", _this);

      gaEvent("continue_in", {
        event_category: _this.$parent.pageName,
        event_label: name,
      });
      if (_this.$store.state.isDown == 2) {
        gaEvent("no_download", {
          event_category: _this.$parent.pageName,
          event_label: "null",
        });
        _this.$confirm(_this.$t(`message.commonTip.alertTip[6].poptext`), {
          confirmButtonText: _this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
          type: "none",
          closeOnClickModal: false
        })
          .then(() => {
            gaEvent("no_download_leave", {
              event_category: _this.$parent.pageName,
              event_label: "null",
            });
            window.location.href = url
          })
          .catch(() => {
          });
      } else {
        window.location.href = url
      }
    },
    //完成一次转化就调用一次
    settle() {
      this.$http
        .get("/app/user/opt-settle").then((res) => {
          console.log("免费次数-1");
        })
    }


  },
  mounted() {
    this.nowTime = Math.round(new Date().valueOf() / 1000)
    const _this = this;
    //如果缓存有下载地址就用缓存
    // if (localStorage.getItem('savePath')) {
    //   this.downSavePath = localStorage.getItem('savePath')
    //   console.log("默认下载地址:", this.downSavePath);
    // }
    electron.ipcRenderer.on('downloadState', (event, arg) => {
      console.log('渲染进程收到的消息:', arg, new Date().getTime())
      if (arg) {
        this.downloadFail = true
        // this.$message.error('The file download failed, please click Save As to download again.')
        _this.downloadFailGa()
        console.log("下载失败:", arg);
      }
    })
    console.log('down----------:', this);
    console.log('down----------:', this.$parent);
    let funName = this.$store.state.funName;
    if (funName == "split") {
      this.comName = "pdnob_split";
    } else if (funName == "merge") {
      this.comName = "pdnob_merged";
    } else if (funName == "watermarked") {
      this.comName = "pdnob_watermarke";
    } else if (funName == "compress") {
      this.comName = "pdnob_compressed";
    } else if (funName == "removeWater") {
      this.comName = "pdnob_removewatermark";
    } else if (funName == "rotate") {
      this.comName = "pdnob_rotated";
    } else if (funName == "protect") {
      this.comName = "pdnob_protected";
    } else if (funName == "delete") {
      this.comName = "pdnob_deleted";
    }
    // else if (funName == "extract images") {
    //   this.comName = "pdnob_split";
    // } else if (funName == "extract pages") {
    //   this.comName = "pdnob_split";
    // }
    this.downFileName = this.$parent.fileList[0].name;
    this.$store.commit('changeDown', 2)
    // this.downFileName = this.downFileName.substring(0, this.downFileName.lastIndexOf(".")) + "_" + this.$store.state.funName + "." + this.downFileType;
    this.downFileName = this.comName + '_' + this.downFileName.substring(0, this.downFileName.lastIndexOf(".")) + "." + this.downFileType;
    // //console.log("this.downFileType:", this.downFileType, this.$parent.currentFun);
    if (this.downFileType == "doc" || this.downFileType == "docx") {
      this.downFileOffName = "Word";
      this.downFileOffImg =
        require("../../assets/download/word-file.svg");
    } else if (this.downFileType == "xls" || this.downFileType == "xlsx") {
      this.downFileOffName = "Excel";
      this.downFileOffImg =
        require("../../assets/download/excel-file.svg");
    } else if (this.downFileType == "ppt" || this.downFileType == "pptx") {
      this.downFileOffName = "PPT";
      this.downFileOffImg =
        require("../../assets/download/ppt-file.svg");
    } else if (this.downFileType == "txt") {
      this.downFileOffName = "TEXT";
      this.downFileOffImg =
        require("../../assets/download/txt-file.svg");
    } else if (this.downFileType == "pdf") {
      this.downFileOffName = "PDF";
      this.downFileOffImg =
        require("../../assets/merge/filebg.svg");
    } else if (this.downFileType == "zip" && this.$parent.currentFun == 'jpgToPdf') {
      this.downFileOffName = "PDF";
      this.downFileOffImg =
        require("../../assets/download/zip-file.svg");
    } else if (this.downFileType == "zip" && this.$parent.currentFun == 'pdfToHtml') {
      this.downFileOffName = "PDF";
      this.downFileOffImg =
        require("../../assets/download/zip-file.svg");
    }
    this.successText = this.$t(`message.successText[0]`);
    if (this.downFileType == "zip") {
      this.downFileOffImg =
        require("../../assets/download/zip-file.svg");
    }
    this.gaCurrentCat = this.$parent.currentFun
      .replace(/pdf/i, "PDF")
      .replace("To", " to ")
      .replace("word", "Word")
      .replace(/text/i, "TEXT")
      .replace("ppt", "PPT")
      .replace("excel", "Excel")
      .replace("jpg", "Images")
      .replace("Img", "jpg")
      .replace(/html/i, "HTML");
    // //console.log("_this.downFileOffName.toLowerCase():", _this.downFileOffName.toLowerCase());
    if (_this.downFileOffName.toLowerCase() == "pdf" && _this.$store.state.funName != 'protect') {
      console.log("下载页pre-icon:", this.$store.state.downloadPdfImg);
      document.querySelector(".pre-icon").src = this.$store.state.downloadPdfImg
      document.querySelector(".pre-icon-wrap").classList.add('pre-icon-conner')
      document.querySelector(".pre-icon").style.opacity = '1'
      _this.downUrl = 'http://' + _this.downUrl.split('//')[1]
      _this.loadFile(_this.downUrl);
    } else if (_this.downFileOffName.toLowerCase() == "pdf" && _this.$store.state.funName == 'protect') {
      _this.loadFile(_this.$store.state.appFileList[0].fileUrl);
      // this.downFileOffImg
    }
    this.enterComTime = new Date().getTime()
    this.$parent.fileList = []
    this.clickDown()
    console.log("调用settle");
    this.settle() //到下载页面则减少一次免费次数
    console.log("this.nowTime >= this.$store.state.userInfo.vip_expired_time:", this.nowTime >= this.$store.state.userInfo.vip_expired_time);
    if (this.$store.state.userInfo.vip_expired_time && this.nowTime < this.$store.state.userInfo.vip_expired_time) {

      console.log(document.getElementById('down-pre-area'));
      console.log(document.getElementById('down-pre-area').style.height = '548px');
    }
  },
  beforeRouteLeave() {
    // this.downflag;
    const _this = this;
    // //console.log("---------fileList");

    if (this.downflag) {
      window.location.href = _this.$route.path + "?from_restat";
    } else {
      // //console.log("跑了");
    }
  }
};
</script>
<style lang="scss" scoped>
.apptip {
  margin-top: 50px;
  width: 554px;

  .apptipTop {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: rgba(29, 29, 31);
    margin-bottom: 8px;
  }

  .apptipP {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(29, 29, 31);
  }
}

.fileUpload {
  height: 512px;
}

.fileUpload-Box {
  display: flex;
  height: 100%;
}

.purspan {
  color: #5452F6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.tip {
  margin-top: 50px;
  width: 554px;

  .tipTop {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: rgba(29, 29, 31, 0.7);
    margin-bottom: 8px;
  }

  .tipP {
    margin-top: 3px;
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(29, 29, 31, 0.5);
  }
}

.box-right {
  display: flex;
  text-align: left;
  flex-direction: column;
  margin-left: 80px;
  /* justify-content: center; */
  /* height: 512px; */
  margin-top: 100px;

  h3 {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #1D1D1F;
    margin-bottom: 16px
  }

  .boxp {
    width: 600px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(29, 29, 31, 0.7);
  }


}

.box-button {
  display: flex;
  margin-top: 32px;
}

.box-right-DownButton {
  width: 110px;
  height: 32px;
  border: 1px solid #5452F6;
  border-radius: 33px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #5452F6;

  .loading {
    margin-right: 20px;
  }

  &:hover {
    background: #3E3BFF;
    color: #FFFFFF;
    box-shadow: 0px 10px 16px rgba(84, 82, 246, 0.3);
  }

  &:active {
    background: #3432D6;
    color: #FFFFFF;
    box-shadow: 0px 10px 16px rgba(84, 82, 246, 0.3);
  }
}

.buttomDiv {
  width: 1224px;
  height: 36px;
  background: #EEF5FF;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  line-height: 34px;
  color: rgba(29, 29, 31, 0.7);
}

.disabled {
  background: #CCCCD9;
}

.cursorWait {
  cursor: wait;
}

.box-right-RetryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  border-radius: 28px;
  color: #5452F6;
  font-weight: 700;
  width: 260px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;

    color: #5452F6;

    &:hover {
      color: #3E3BFF !important;
      text-decoration: underline;
    }

    &:active {
      color: #3432D6 !important;

    }
  }

  img {
    margin-right: 10px;
  }


}

.continue-bottom {
  display: flex;
  flex: 1;
  width: 100%;
  padding-top: 10px;
  justify-content: space-between;

  .continue-item {
    /* display: flex;
      justify-content: space-around; */
    background: #F5F7FD;
    border-radius: 8px;
    padding: 18px 0;
    width: 22.5%;
    border: 1px solid #fff;

    a {
      /* margin-left: -25px; */
      color: black !important;
      vertical-align: middle;
    }

    :first-child {
      margin: 0 15px 0 15px
    }

    :nth-child(3) {
      float: right;
      margin: 7.5px 15px 0 10px
    }

    &:hover {
      border: 1px solid #5452F6;
      background: #ECECFF;

      .rightimg {
        content: url("../../assets/download/activeArrow.svg");
      }
    }

    &:active {
      background: #DDDCFD;

      .rightimg {
        content: url("../../assets/download/activeArrow.svg");
      }
    }
  }
}

.down-name-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;

  &:hover {
    #file-name-hover {
      display: block !important;
    }
  }

  #file-name {
    color: #001637;
    line-height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    transform: translateY(2px);
  }

  #file-name-hover {
    width: 120%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    /* display: block; */
    background: #ffffff;
    padding: 2px 6px;
    /* opacity: 0; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    transition: all 0.4s;
    box-shadow: 0px 2px 8px rgba(33, 34, 42, 0.1);
    word-break: break-all;
    display: none;
    /* &:hover {
        opacity: 1;
      } */
  }
}

#down-pre-area {
  box-sizing: border-box;
  width: 360px;
  height: 512px;
  /* background: #F5F7FD; */
  background: url('../../assets/download/downloadBack.png');
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

#down-area {
  padding-top: 40px;
  box-shadow: 1px 0px 10px #eaeaea;
  height: 100%;

  h2 {
    font-weight: 700;
    font-size: 20px;
    color: #001637;
  }




  #to-home {
    color: #001637;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.4;
    font-weight: 500;
    margin-top: 12px;

    &:hover {
      cursor: pointer;
    }
  }


  #file-size {
    margin-top: 12px;
    color: #001637;
    font-size: 14px;
    font-weight: 500;

    line-height: 18px;
    opacity: 0.5;
  }
}

.success-icon {
  position: relative;
  top: 66px;
  right: 16px;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.right-icon {
  position: relative;
  right: 190px;
  height: 212px;
  width: 172px;
}

canvas {
  display: none;
}

.pre-icon-wrap {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 120px;
  /* height: 200px; */
  margin: 0 auto;

  .pre-icon {
    max-width: 120px;
    min-width: 120px;
    max-height: 170px;
    z-index: 1;
    opacity: 0;
  }

  &.pre-icon-conner {
    /* box-shadow: 0px 6px 11px rgb(33 34 42 / 10%); */
    position: relative;
    /* border-radius: 0 52px 0 0; */
    /* overflow: hidden; */

    /* &::after {

        content: "";
        width: 38px;
        height: 41px;
        background: url("../assets/convert/success.svg") no-repeat left bottom;
        position: absolute;
        top: 0;
        right: 0;
      } */
  }
}
</style>
<style lang="scss">
.appDia .el-dialog {
  margin-top: 28vh !important;
  border-radius: 8px;
}


.appDia .el-dialog__body {
  padding: 0px 20px 16px 20px
}

.appDia .el-dialog__header {
  padding-bottom: 0px !important;
}


.appDia .dialog-footer {
  display: flex;
  justify-content: right;
  margin-top: 24px;
}

.appDia .el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #909399 !important;
}

.appDia .el-button--primary {
  background: #5452F6 !important;
  width: 110px;
  height: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  border-radius: 40px;

  &:hover {
    background: #3E3BFF !important;
    color: white;
    box-shadow: 0px 5px 8px rgba(84, 82, 246, 0.3);
  }

  &:active {
    background: #3432D6 !important;
    color: white;
    box-shadow: 0px 5px 8px rgba(84, 82, 246, 0.3);
  }
}
</style>