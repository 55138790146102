<template lang="">
  <!-- 中间放文件 -->
  <!-- <img src="../assets/download/excel-file.svg" alt=""> -->
  <div class="fun-area-wrap" :uploadingFlag="$store.state.uploadingFlag"
    v-if="(checkAppState() == 'start' ||checkAppState() == 'operate') &&  pageId != 13">
    <div @click="changeClickOrDrag()">
      <el-upload v-if="!$store.state.uploadingFlag" class="upload-demo upload-area dotteBox" drag ref="upload" action=""
        :auto-upload="false" :before-upload="beforeUpload" :show-file-list="false" :on-change="clickUpload"
        :file-list="fileList" :disabled="$store.state.uploadingFlag" :accept="fileTypeUpload">
        <div class="flexBox" v-for="(item,index) in list" :key="index">
          <div v-show="pageId == item.id" style="display:flex;flex-direction: column;align-items: center;">
            <img class="ptw" :src="item.src" alt="">
            <p
              style="font-family: 'Inter';font-style: normal;font-size:32px;font-weight:bold;margin:40px 0 8px 0px;line-height: 44px;">
              {{item.text}}</p>
            <p
              style="font-family: 'Inter'; font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{item.p}}</p>
            <p
              style="font-family: 'Inter';font-style: normal;margin:64px 0 8px 0;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.selectFile[2]`)}}
            </p>
            <div class="selectButton cursor">{{$t(`message.selectFile[0]`)}}</div>
            <div style="margin-top:32px" v-if="operation == 'Windows'">
              <img src="../assets/purchase/download.svg" alt="">
              <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.33317 3H3.6665C3.11422 3 2.6665 3.4477 2.6665 4V11H13.3332V8.33333" stroke="#8E8E8F"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M1.3335 11H14.6668V11.6667C14.6668 12.7712 13.7714 13.6667 12.6668 13.6667H3.3335C2.22893 13.6667 1.3335 12.7712 1.3335 11.6667V11Z"
                  stroke="#8E8E8F" stroke-linejoin="round" />
                <path d="M10.3335 4.66669L12.0002 6.33335L13.6668 4.66669" stroke="#8E8E8F" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M12 2.33331V6.33331" stroke="#8E8E8F" stroke-linecap="round" stroke-linejoin="round" />
              </svg> -->
              <span
                style="font-family: 'Inter';font-style: normal;font-weight: 400;font-size: 16px;text-align: center;color: rgba(29, 29, 31, 0.3);">
                To batch process the conversion files, <span class="showDown" @click.stop="download"
                  @mouseenter="handleEnter" @mouseleave="handleLeave">please download the software on your
                  PC.</span></span>

            </div>
            <div v-else style="margin-top:32px">
              <span>
                <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style="margin-top:-3px ;">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.65811 0.0256583C6.55548 -0.00855278 6.44452 -0.00855278 6.34189 0.0256583L0.341886 2.02566C0.137715 2.09372 0 2.28478 0 2.5V9.5C0 9.60554 0.0333988 9.70838 0.0954135 9.79379C0.286104 10.0564 0.470108 10.3171 0.651201 10.5737L0.651239 10.5737C1.27002 11.4504 1.85483 12.2789 2.5563 12.9713C3.49179 13.8947 4.64103 14.5911 6.38941 14.9876C6.46157 15.004 6.53646 15.0041 6.60868 14.988C9.8687 14.2621 11.3239 12.288 12.9222 9.7678C12.973 9.68771 13 9.59483 13 9.5V2.5C13 2.28478 12.8623 2.09372 12.6581 2.02566L6.65811 0.0256583ZM1 9.33811V2.86038L6.5 1.02705L12 2.86038V9.35452C10.479 11.7411 9.22029 13.3468 6.50088 13.9869C4.99483 13.6278 4.04041 13.0311 3.25878 12.2596C2.62646 11.6355 2.11047 10.9051 1.50375 10.0462L1.50369 10.0461C1.3434 9.81922 1.17677 9.58335 1 9.33811ZM9.89468 5.80696C10.0642 5.58899 10.0249 5.27485 9.80697 5.10532C9.589 4.93578 9.27486 4.97505 9.10532 5.19302L5.95305 9.24594L4.35355 7.64644C4.15829 7.45118 3.84171 7.45118 3.64645 7.64644C3.45118 7.8417 3.45118 8.15828 3.64645 8.35355L5.64645 10.3535C5.74786 10.455 5.88793 10.5079 6.03107 10.499C6.17421 10.4901 6.30663 10.4202 6.39468 10.307L9.89468 5.80696Z"
                    fill="#BBBBBC" />
                </svg>
                <span
                  style="color: rgba(29, 29, 31, 0.3);margin-left:8px;font-family: 'Inter';font-style: normal;font-size: 16px;color: rgba(29, 29, 31, 0.3);">{{$t(`message.selectText[0]`)}}</span>
              </span>
            </div>
          </div>
        </div>
      </el-upload>
      <!-- <uploading /> -->

      <div v-if="$store.state.uploadingFlag" style="height:100%">
        <uploading />
      </div>
    </div>
  </div>
  <pdfToWord class="fun-area-wrap" v-if="currentFun == 'pdfToWord' && pageId == 6" :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:downFileType.sync="downInof.downFileType"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState" @checkFileType="checkFileType" />
  <pdfToImg class="fun-area-wrap" v-if="currentFun == 'pdfToImg' && pageId == 7 " :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    @checkFileType="checkFileType" @removeFile="removeFileFun" />
  <pdfToExcel class="fun-area-wrap" v-if="currentFun == 'pdfToExcel' && pageId == 8 " :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @checkFileType="checkFileType" />
  <pdfToTexT class="fun-area-wrap" v-if="currentFun == 'pdfToText' && pageId == 10" :fileList="fileList"
    :uploadState="uploadState" v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" @checkFileType="checkFileType" />
  <pdfToPPT class="fun-area-wrap" v-if="currentFun == 'pdfToPPT' && pageId == 9 " :fileList="fileList"
    v-model:downUrl.sync="downInof.downUrl" v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @checkFileType="checkFileType" />
  <pdfToHtml class="fun-area-wrap" v-if="currentFun == 'pdfToHtml' && pageId == 11" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState" v-model:downUrl.sync="downInof.downUrl"
    @checkFileType="checkFileType" />
  <wordToPdf class="fun-area-wrap" v-if="currentFun == 'wordToPdf' && pageId == 12" :fileList="fileList"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downUrl.sync="downInof.downUrl" />
  <textToPdf class="fun-area-wrap" v-if="currentFun == 'textToPdf' && pageId == 16" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" />
  <pptToPdf class="fun-area-wrap" v-if="currentFun == 'pptToPdf' && pageId == 15" :fileList="fileList"
    v-model:currentFun.sync="currentFun" v-model:uploadState.sync="uploadState"
    v-model:downUrl.sync="downInof.downUrl" />
  <excelToPdf class="fun-area-wrap" v-if="currentFun == 'excelToPdf' && pageId == 14" :fileList="fileList"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun "
    v-model:downUrl.sync="downInof.downUrl" />
  <!-- {{currentFun}}{{pageId}} -->
  <jpgToPdf class="fun-area-wrap" v-if="currentFun == 'jpgToPdf' && pageId == 13" v-model:fileList="fileList"
    v-model:uploadingFlag.sync="$store.state.uploadingFlag" v-model:downUrl.sync="downInof.downUrl"
    v-model:uploadState.sync="uploadState" v-model:currentFun.sync="currentFun"
    v-model:downFileType.sync="downInof.downFileType" @clickUpload="clickUpload" @changeRotate="changeRotate"
    @addPreImg="addPreImg" @changeFileListToJpgFileList="changeFileListToJpgFileList" />
  <failUpload class="fun-area-wrap" v-if="checkAppState() == 'failUpload'" />
  <!-- <failUpload class="fun-area-wrap" /> -->
  <!-- <converting class="fun-area-wrap" /> -->
  <failConvert class="fun-area-wrap" v-if="checkAppState() == 'failConvert'" />
  <!-- <failConvert class="fun-area-wrap" /> -->
  <download class="fun-area-wrap" v-if="checkAppState() == 'converted'" v-model:downUrl.sync="downInof.downUrl"
    v-model:downflag.sync="downInof.downflag" v-model:currentFun.sync="currentFun" />
  <!-- <warningPop v-if="warningFlag == true"></warningPop> -->
</template>

<script>
  // import warningPop from '../components/warningPop.vue'
  import converting from "../components/common/converting.vue";
  import uploading from "../components/common/uploading.vue";
  import failConvert from "../components/failConvert.vue";
  import failUpload from "../components/failUpload.vue";
  import download from '../components/Download'
  import PDF from 'pdfjs-dist'
  import { openPop, removeSingleFile } from '../api/common'
  // import { convertFun } from "../components/convertFun/convert";
  import pdfToWord from "../components/convertFun/PDFtoFile/pdfToWord.vue";
  import pdfToImg from "../components/convertFun/PDFtoFile/pdfToImg.vue";
  import pdfToExcel from "../components/convertFun/PDFtoFile/pdfToExcel.vue";
  import pdfToTexT from "../components/convertFun/PDFtoFile/pdfToText.vue";
  import pdfToPPT from "../components/convertFun/PDFtoFile/pdfToPPT.vue";
  import pdfToHtml from "../components/convertFun/PDFtoFile/pdfToHtml.vue";
  import wordToPdf from "../components/convertFun/fileToPDF/wordToPdf.vue";
  import textToPdf from "../components/convertFun/fileToPDF/textToPdf.vue";
  import pptToPdf from "../components/convertFun/fileToPDF/pptToPdf.vue";
  import excelToPdf from "../components/convertFun/fileToPDF/excelToPdf.vue";
  import jpgToPdf from "../components/convertFun/fileToPDF/jpgToPdf.vue";
  export default {
    data() {
      return {
        fileList: [],
        fileType: '',
        color: false,
        fileTypeUpload: "application/pdf",
        uploadingFlag: false,
        uploadSusFlag: false,
        warningFlag: false,
        currentFun: '',
        toFileTaret: '',
        clickFlag: false,
        uploadState: '0',
        fileLimited: 1,
        downInof: {
          downUrl: '',
          downName: '',
          downFileType: '',
        },
        operation: '',
        htmlToPdf: {
          flag: false,
          url: '',
          urlWrongFlag: true,
          urlWrongText: '',
        },
        pdfImgList: [],
        preImgSrc: 'https://pdnob.passfab.com/images/pdnob1-4/icon-off-pdf2.svg',
        pages: 0,
        canvasList: [],
        occupancyFlag: true,
        getFileFromState: false,
        clickUploadFlag: false,
        pageId: 6,
        pageName: "",//当前页面是什么功能，埋点用
        fromPageName: "",//来自什么页面
        taskId: '',//任务id
        list: [
          {
            id: 6,
            src: require("../assets/convert/PTW.svg"),
            text: this.$t(`message.Navigation.pdf.info[0]`),
            p: this.$t(`message.PdfTofile.text[0]`),
          },
          {
            id: 7,
            src: require("../assets/convert/PTJ.svg"),
            text: this.$t(`message.Navigation.pdf.info[4]`),
            p: this.$t(`message.PdfTofile.text[3]`),
          },
          {
            id: 8,
            src: require("../assets/convert/PTE.svg"),
            text: this.$t(`message.Navigation.pdf.info[1]`),
            p: this.$t(`message.PdfTofile.text[1]`),
          },
          {
            id: 9,
            src: require("../assets/convert/PDTPP.svg"),
            text: this.$t(`message.Navigation.pdf.info[2]`),
            p: this.$t(`message.PdfTofile.text[2]`),
          },
          {
            id: 10,
            src: require("../assets/convert/PTT.svg"),
            text: this.$t(`message.Navigation.pdf.info[3]`),
            p: this.$t(`message.PdfTofile.text[4]`),
          },
          {
            id: 11,
            src: require("../assets/convert/PTH.svg"),
            text: this.$t(`message.Navigation.pdf.info[5]`),
            p: this.$t(`message.PdfTofile.text[5]`),
          },
          {
            id: 12,
            src: require("../assets/convert/WTP.svg"),
            text: this.$t(`message.Navigation.pdf.info[6]`),
            p: this.$t(`message.PdfTofile.text[6]`),
          },
          {
            id: 13,
            src: require("../assets/convert/ITP.svg"),
            text: this.$t(`message.Navigation.pdf.info[10]`),
            p: this.$t(`message.PdfTofile.text[10]`),
          },
          {
            id: 14,
            src: require("../assets/convert/ETP.svg"),
            text: this.$t(`message.Navigation.pdf.info[7]`),
            p: this.$t(`message.PdfTofile.text[7]`),
          },
          {
            id: 15,
            src: require("../assets/convert/PPTPD.svg"),
            text: this.$t(`message.Navigation.pdf.info[8]`),
            p: this.$t(`message.PdfTofile.text[8]`),
          },
          {
            id: 16,
            src: require("../assets/convert/TTP.svg"),
            text: this.$t(`message.Navigation.pdf.info[9]`),
            p: this.$t(`message.PdfTofile.text[9]`),
          }
        ]
      }
    },
    methods: {
      openPop,
      removeSingleFile,
      clearFileList() {
        this.fileList = []
      },
      download() {
        // window.open("http://www.baidu.com")
        this.$store.commit('changeIsShowPur', true)
      },
      handleEnter() {
        document.getElementsByClassName('el-upload-dragger')[0].classList.add('aaa')
        console.log("document.getElementsByClassName('el-upload-dragger'):", document.getElementsByClassName('el-upload-dragger')[0].classList);
        // document.getElementsByClassName('el-upload-dragger')[0].removeClass('hover')
      },
      handleLeave() {
        document.getElementsByClassName('el-upload-dragger')[0].classList.remove('aaa')
      },
      removeFileFun() {
        ////console.log("remove");
        // this.uploadingFlag = false
        this.$store.commit('changeUploadingFlag', false)
        this.clickFlag = false
        this.fileList = []
      },
      removehtmlUrl() {
        this.htmlToPdf.urlWrongFlag = true
      },
      changeRotate(fileNum, addFlag) {
        console.log("fileNum：", fileNum, addFlag);
        this.fileList[fileNum].rotate += 90
      },
      changeFileListToJpgFileList(newObj) {
        this.fileList = newObj
      },
      checkAppState() {
        return this.$store.state.appState
      },
      changeClickOrDrag() {
        this.clickUploadFlag = true
      },

      //上传调用
      clickUpload(fileMsg) {
        console.log('fileMsg:', fileMsg)
        const _this = this

        this.fileList.push(fileMsg)
        this.fileType = this.fileList[0].name.toLowerCase().substr(this.fileList[0].name.lastIndexOf('.') + 1)
        ////console.log('fileType:', this.fileType, this.fileList)
        this.$gtag.event("upload", {
          event_category: _this.pageName,
          event_label: "null",
        });
        if (this.checkFile(fileMsg)) {
          // this.uploadingFlag = true
          this.$store.commit('changeUploadingFlag', true)
          _this.$store.commit("changeAppState", "operate");
          if (this.checkAppState() == 'start') {
            this.checkFileType()
          }
          this.clickFlag = true
          const event = event || window.event
          const file = fileMsg.raw
          this.fileList[this.fileList.length - 1]['document'] = file
          this.fileList[this.fileList.length - 1]['rotate'] = 0
          if (this.fileType == 'pdf') {
            let reader = new FileReader()
            reader.readAsDataURL(fileMsg.raw)
            reader.onload = () => {
              _this.loadFile(reader.result, fileMsg)
            }
            reader.onerror = () => { }
          } else {
            this.uploadToServerProgress()
          }
        }
      },

      reTry(times, uploadFile, num, _this) {
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, uploadFile, num, _this) {
            // console.log('err===>0')
            try {
              // //发送数据
              // xhr.send(formData);
              _this.$http
                .post('app/uploadFile', {
                  token: _this.$store.state.pdfToken,
                  'files[]': uploadFile.document,
                  task_id: _this.taskId
                }).then((res) => {
                  let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                  if (res.code == 200 && _this.$store.state.appState == "operate") {
                    _this.uploadState = '1'
                    _this.fileList[num]['fileUrl'] = res.data.response_file[0]
                    _this.pdfUrl = res.data.response_file[0]
                    _this.checkCurrentFun()
                    // convertFun(true)
                  } else {
                    _this.uploadState = '0'
                  }
                  // _this.$gtag.event("upload_success", {
                  //   event_category: _this.pageName,
                  //   event_label: "null",
                  // });
                  // _this.$gtag.event("upload_file", {
                  //   event_category: _this.pageName,
                  //   event_label: "success",
                  // });
                  _this.$gtag.event("upload_success1", {
                    event_category: _this.pageName,
                    event_label: (uploadFile.size / 1024 / 1024).toFixed(3),
                  });
                  _this.$gtag.event("upload_success2", {
                    event_category: _this.pageName,
                    event_label: `${updateTime}`,
                  });
                  // _this.$gtag.event("upload_success", {
                  //   event_category: _this.pageName,
                  //   event_label: "1",
                  // });
                  resolve(res)
                }).catch(function (err) {
                  // console.warn(Object.keys)
                  console.log("http: err:", err);
                  console.log("http: err:", Object.keys(err));
                  if (times > 0) {
                    setTimeout(() => {
                      reTryFunc(times - 1, uploadFile, num, _this)
                    })
                  } else {
                    let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                    // console.log("回去吧:", err);
                    if (_this.$store.state.appState == "start") return
                    _this.clearFileList()
                    _this.uploadState = '0'
                    _this.$store.commit("changeAppState", "failUpload");
                    _this.$message({
                      showClose: true,
                      duration: 8000,
                      type: "error",
                      message: "The network is abnormal. Please check the network connection.",
                    });
                    // _this.$gtag.event("upload_file", {
                    //   event_category: _this.pageName,
                    //   event_label: "fail",
                    // });

                    _this.$gtag.event("upload_fail1", {
                      event_category: _this.pageName,
                      event_label: (uploadFile.size / 1024 / 1024).toFixed(3),
                    });
                    _this.$gtag.event("upload_fail2", {
                      event_category: _this.pageName,
                      event_label: updateTime,
                    });
                    _this.$gtag.event("upload_fail3", {
                      event_category: _this.pageName,
                      event_label: "500",
                    });
                    reject(err)
                  }
                })
            } catch (err) {
              console.log('err===>', err)
            }
            // //console.log(`第${times}次重试`);

          }
          reTryFunc(times, uploadFile, num, _this)
        })
      },

      //上传文件并返回文件路径
      uploadToServerProgress() {
        if (this.fileList.length > 0) {
          // let gaStartime = new Date().getTime();
          this.uploadState = '2'
          const _this = this
          const num = this.fileList.length - 1
          const uploadFile = this.fileList[num].document
          ////console.log('uploadFile:', uploadFile)
          this.reTry(3, this.fileList[num], num, _this)
        }
      },
      checkFile(filemsg) {
        ////console.log("检测文件：", this.pageId);
        // let gaStartime = new Date().getTime()
        let _this = this
        if ((this.pageId == 6 || this.pageId == 7 || this.pageId == 8 || this.pageId == 9 || this.pageId == 10 || this.pageId == 11) && this.fileType != 'pdf') {
          ////console.log("选错了6:");
          // let uploadTime = (((new Date().getTime() - gaStartime) / 1000) * 100).toFixed(1) / 100
          this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          // this.$gtag.event("upload_fail", {
          //   event_category: _this.pageName,
          //   event_label: "0.1",
          // });
          // this.$gtag.event("upload_file", {
          //   event_category: _this.pageName,
          //   event_label: "fail",
          // });
          // this.$gtag.event("upload_fail", {
          //   event_category: _this.pageName,
          //   event_label: "format_error",
          // });
          this.fileList = []
          return false
        } else if (this.pageId == 12 && (this.fileType != 'doc' && this.fileType != 'docx')) {
          ////console.log("选错了12", this.fileType);
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          return false
        } else if ((this.fileType != 'jpg' && this.fileType != 'png' && this.fileType != 'bmp'
          && this.fileType != 'tiff' && this.fileType != 'gif'
          && this.fileType != 'jpeg' && this.fileType != 'tif') && this.pageId == 13) {
          ////console.log("选错了13：", this.fileType);
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          return false
        } else if (this.pageId == 14 && (this.fileType != 'xls' && this.fileType != 'xlsx')) {
          ////console.log("选错了14");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          return false
        } else if (this.pageId == 15 && (this.fileType != 'ppt' && this.fileType != 'pptx')) {
          ////console.log("选错了15");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          return false
        } else if (this.pageId == 16 && this.fileType != 'txt') {
          //console.log("选错了16");
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.commonTip.alertTip[1].poptext`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          return false
        }
        if (filemsg.size > 30 * 1024 * 1024) {
          this.$message({
            type: "error",
            showClose: true,
            duration: 8000,
            message: _this.$t(`message.UploadFun.message[0]`),
          });
          _this.$gtag.event("upload_fail1", {
            event_category: _this.pageName,
            event_label: (filemsg.size / 1024 / 1024).toFixed(3),
          });
          _this.$gtag.event("upload_fail2", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          _this.$gtag.event("upload_fail3", {
            event_category: _this.pageName,
            event_label: "400",
          });
          this.fileList = []
          this.$store.commit("changeAppState", "start");
          if (this.operation == 'Windows') {
            this.$store.commit('changeIsShowPur', true)
          }

          return false
        }
        return true
      },
      checkFileType() {
        this.$store.commit('changeFileType', this.fileType)
        if (this.fileType == 'pdf') {
          this.$store.commit('checkPDF', true)
          //console.log("--------------");
        } else {
          this.$store.commit('checkPDF', false)
        }
      },
      //check current funcion and save in vuex
      //if filyType is pdf ,change to step operate,else change step to operate after click current fun.
      //如果文件类型不为pdf则下载类型改为pdf存到vuex中，如果文件类型不为pdf则下载类型改为pdf存到vuex中
      checkCurrentFun(targetCurrent, targetFileType) {
        console.log("this.fileType:", this.fileType, this.pageId);
        if (this.pageId == 6) {
          targetCurrent = 'pdfToWord'
          targetFileType = 'docx'
          this.fileType = 'pdf'
        } else if (this.pageId == 7) {
          targetCurrent = 'pdfToImg'
          targetFileType = 'zip'
          this.fileType = 'pdf'
        } else if (this.pageId == 8) {
          targetCurrent = 'pdfToExcel'
          targetFileType = 'xlsx'
          this.fileType = 'pdf'
        } else if (this.pageId == 9) {
          targetCurrent = 'pdfToPPT'
          targetFileType = 'pptx'
          this.fileType = 'pdf'
        } else if (this.pageId == 10) {
          targetCurrent = 'pdfToText'
          targetFileType = 'txt'
          this.fileType = 'pdf'
        } else if (this.pageId == 11) {
          targetCurrent = 'pdfToHtml'
          targetFileType = 'zip'
          this.fileType = 'pdf'
        } else if (this.pageId == 13) {
          console.log("jpgtopdf");
          this.currentFun = 'jpgToPdf'
        }
        if (this.fileType != 'pdf') {
          this.downInof.downFileType = 'pdf'
          // this.$store.commit('changeAppState', 'operate')
        }
        if (this.fileType == 'doc' || this.fileType == 'docx') {
          this.currentFun = 'wordToPdf'
          // window.location.href='./convert?from_toplist&from_other_excel'
        } else if (this.fileType == 'txt' || this.fileType == 'text') {
          this.currentFun = 'textToPdf'
        } else if (this.fileType == 'ppt' || this.fileType == 'pptx') {
          this.currentFun = 'pptToPdf'
        } else if (this.fileType == 'xlsx' || this.fileType == 'xls') {
          this.currentFun = 'excelToPdf'
        } else if (
          this.fileType == 'jpg' || this.fileType == 'png' ||
          this.fileType == 'bmp' || this.fileType == 'jfil' ||
          this.fileType == 'tiff' || this.fileType == 'gif' ||
          this.fileType == 'jpeg' || this.fileType == 'tif'
        ) {
          console.log("jpg");
          this.currentFun = 'jpgToPdf'
          this.fileLimited = 5
        }
        //console.log("targetCurrent:", targetCurrent);
        if (targetCurrent) {
          // const _this = this
          console.log("11111:", targetCurrent);
          this.currentFun = targetCurrent
          this.downInof.downFileType = targetFileType
          this.$store.commit('changeAppState', 'operate')
        }
        //console.log("---------:", this.currentFun, this.pageId);
      },
      addPreImg(num, imgBlob) {
        console.log("addPreImg");
        this.fileList[num].preImg = imgBlob
      },
      loadFile(url, file) {
        const _this = this
        PDF.getDocument(url)
          .then((pdf) => {
            //console.log('enter loadFile', pdf)
            this.pdfDoc = pdf
            this.pages = this.pdfDoc.numPages
            this.$nextTick(() => {
              this.uploadToServerProgress()
            })
          })
          .catch(function () {
            _this.fileList = []
            _this.$store.commit('changeAppState', 'start')
            _this.$store.commit('changeUploadingFlag', false)
            _this.clickFlag = false
            _this.openPop(_this, 4)
            console.log("this.fileList:", _this.fileList);
            _this.$gtag.event("upload_fail1", {
              event_category: _this.pageName,
              event_label: (file.size / 1024 / 1024).toFixed(3),
            });
            _this.$gtag.event("upload_fail2", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            _this.$gtag.event("upload_fail3", {
              event_category: _this.pageName,
              event_label: "400",
            });
            return false
          })
      },
      //监听路由改变pageId
      changePageId() {
        //console.log("this.$router.fullPath:", this.$route.fullPath);
        if (this.$route.fullPath.indexOf('to_word') > 0) {
          //console.log(666);
          this.pageId = 6
          this.pageName = "PDF_to_Word"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_jpg') > 0) {
          //console.log(777);
          this.pageId = 7
          this.pageName = "PDF_to_JPG"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_excel') > 0) {
          //console.log(888);
          this.pageId = 8
          this.pageName = "PDF_to_Excel"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_ppt') > 0) {
          //console.log(999);
          this.pageId = 9
          this.pageName = "PDF_to_PPT"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_text') > 0) {
          this.pageId = 10
          this.pageName = "PDF_to_TXT"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('to_html') > 0) {
          this.pageId = 11
          this.pageName = "PDF_to_HTML"
          this.fileTypeUpload = "application/pdf"
        } else if (this.$route.fullPath.indexOf('from_other_word') > 0) {
          this.pageId = 12
          this.pageName = "Word_to_PDF"
          this.fileTypeUpload = "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        } else if (this.$route.fullPath.indexOf('from_other_jpg') > 0) {
          this.pageId = 13
          this.pageName = "Images_to_PDF"
          this.fileTypeUpload = ".png,.jpg,.bmp,.tiff,.tif,.jpeg,.gif"
        } else if (this.$route.fullPath.indexOf('from_other_excel') > 0) {
          this.pageId = 14
          this.pageName = "Excel_to_PDF"
          this.fileTypeUpload = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        } else if (this.$route.fullPath.indexOf('from_other_ppt') > 0) {
          this.pageId = 15
          this.pageName = "PPT_to_PDF"
          this.fileTypeUpload = ".ppt,.pptx"
        } else if (this.$route.fullPath.indexOf('from_other_text') > 0) {
          this.pageId = 16
          this.pageName = "TXT_to_PDF"
          this.fileTypeUpload = ".txt"
        }
        this.fileList = []
      },

      beforeUpload(e) {
      },
    },
    components: {
      converting,
      download,
      failConvert,
      failUpload,
      pdfToWord,
      pdfToImg,
      pdfToExcel,
      pdfToTexT,
      pdfToPPT,
      pdfToHtml,
      wordToPdf,
      textToPdf,
      pptToPdf,
      excelToPdf,
      jpgToPdf,
      uploading
    },
    watch: {
      $route: {
        handler(to, from) {
          this.changePageId()
          // this.removeFileFun()
          // if (to.fullPath.indexOf("convert") != -1) {
          //   this.fromPage(from)
          // }
          console.log("路由变了哦:", to, from);
        },
        deep: true
      },
      fileList: {
        handler(val) {
          // console.log("convertval:", val);
          const _this = this
          if (val.length > 0) {
            this.downInof.downName = val[0].name
          } else {
            // document.querySelectorAll('.action-area-wrap .cell:not(.toPdf)').forEach((ele) => {
            //   ele.classList.remove('active')
            // })
            _this.clickUploadFlag = false
          }
          this.$store.commit('changeAppFileList', val)
        },
        deep: true, //true 深度监听
      },
      pdfImgList: {
        handler(val) {
          this.preImgSrc = val[0]
        },
        deep: true, //true 深度监听
      },
      currentFun: function () { },
    },
    created() {
      this.$store.commit('changeAppState', 'start')
    },
    mounted() {
      this.operation = sessionStorage.getItem('getOS');
      console.log("this.operation:", this.operation);
      //console.log("route:", this.$route);
      // window.addEventListener('dragover', (e) => //console.log(111))
      //console.log("Convertmounted:", this.$route);
      this.$http
        .get("/app/user/get-taskid").then((res) => {
          this.taskId = res.data.task_id
          console.log("res.data:", res.data);
        })
      this.pageName = "PDF_to_Word"
      this.fileTypeUpload = "application/pdf"
      // this.uploadingFlag = false
      this.$store.commit('changeUploadingFlag', false)
      // this.checkConverFunUseful()
      this.changePageId()
      this.clearFileList()
      if (this.pageId == 13) {
        this.checkCurrentFun()
      }

      // this.fromPage()
    },
  }
</script>

<style lang="scss" scoped>
  .showDown {
    z-index: 999;
    color: #5452F6;

    &:hover {
      text-decoration: underline
    }
  }

  ::v-deep .aaa {
    /* &:hover { */
    border: 2px dashed #eaeaea !important;
    /* } */
  }

  .fun-area-wrap {
    height: 548px;
    // overflow: auto;
    background: #fff;
    border-radius: 16px;
    // margin-top: 20px;
  }

  .action-area-wrap {
    padding: 24px 20px;

    .cell {
      width: 92px;
      text-align: center;
      padding: 15px 0;
      color: #001637;
      opacity: 0.6;
      font-size: 14px;
      font-weight: 300;
      border: 1px solid #eaeaea;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 16px;

      &.active {
        opacity: 1;

        img {
          display: none;
        }

        img.fun-active {
          display: initial;
        }
      }

      .fun-active {
        display: none;
      }

      &.active:hover {
        cursor: pointer;
        border: 1px solid #278bff;
        color: #001637;
        opacity: 1;
        font-weight: 500;
      }
    }
  }

  .flexBox {
    .ptw {
      width: 158px;
      height: 108px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dotteBox {
    /* height: 95%;
    width: 95%; */
    padding: 24px;
    border-radius: 16px;
    /* margin: calc(1.5% - 4px) 0 0 2.5%; */
  }

  .el-upload,
  .el-upload-dragger {
    cursor: initial;
  }

  ::v-deep .el-upload {
    &:focus {
      color: black;
      /* border-color: red */
    }
  }

  [uploadflag='true'] {
    button {
      opacity: 0.6;
    }
  }

  .pre-pdf-img {
    box-shadow: 0px 4px 12px rgba(33, 34, 42, 0.1);
  }

  ::v-deep .is-dragover {
    background-color: #f7f6ff !important;
  }
</style>