<template lang="">
  <el-col :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import {
    changeType,
    checkAppState,
    convertFun,
    backToChooseType,
  } from "../convert";
  import converting from "../../../common/converting.vue";

  import "../convert.scss";

  export default {
    data() {
      return {
        pdfUrl: "",
        pdfFileList: [],
        canConverFlag: false,
        convertNumber: "",
        targetType: "xlsx",
        fileName: this.fileList[0].name,
        checkuploadState: this.uploadState,

      };
    },
    props: ["fileList", "uploadState"],

    components: {
      // pdf,
      converting,
    },
    methods: {
      changeType,
      checkAppState,
      convertFun,
      backToChooseType,
    },
    mounted() {

    },
  };
</script>
<style lang="scss" scoped></style>