<template lang="">
  <el-col :span="24" class="bg-white" v-if="checkAppState() == 'converted'">
    <div class="fileUpload">
      <div class="fileUpload-Box">
        <div id="down-pre-area">
          <div class="pre-icon-wrap">
            <!-- 只有pdf才出现缩略图 -->
            <img class="pre-icon" :src="downFileOffImg" alt="" />
            <!-- <img class="pre-icon" :src="downFileOffImg" alt="" v-if="occupancyFlag" /> -->
            <canvas v-for="page in pages" :id="'canvas' + page" :key="page"></canvas>
            <img class="success-icon" src="../assets/convert/success.svg" alt="" />
            <!-- <img class="right-icon" src="../assets//merge/filebg.svg" alt="" /> -->
            <img class="right-icon" :src="downFileOffImg" alt="" />
          </div>
          <div class="down-name-wrap cursor">
            <p id="file-name" v-if="downFileName.length < 15">
              {{ downFileName.substring(0, downFileName.lastIndexOf(".")) }}.{{ downFileType }}
            </p>
            <p id="file-name" v-else>
              {{ downFileName.substring(0,
              8)}}...{{downFileName.substring(downFileName.lastIndexOf(".")-4,downFileName.lastIndexOf("."))}}.{{
              downFileType }}
            </p>
            <p id="file-name-hover">{{ downFileName }}</p>
          </div>
        </div>
        <div class="box-right ">
          <h3>{{ successText }}</h3>
          <p>{{$t(`message.downloadPag.tip`)}}</p>
          <!-- <div class="box-right-DownButton cursorWait" v-if="downloadingFlag" disabled>
            <span class="el-icon-loading loading"></span>
            <span>{{$t(`message.Downloading[0]`)}}</span>
          </div> -->
          <div class="box-right-DownButton1  disabled" v-if="downloadingFlag">
            <!-- <span class="el-icon-loading loading"></span> -->
            <span>{{$t(`message.Downloading[0]`)}}</span>
          </div>
          <div class="box-right-DownButton cursor" @click="clickDown()" v-else>
            <!-- <span class="el-icon-loading loading"></span> -->
            <span>{{$t(`message.Downloading[0]`)}}</span>
          </div>
          <div class="box-right-RetryButton">
            <img src="../assets/fail/restart.svg" alt="" class="cursor" @click="reloadPage()">
            <span class="cursor" @click="reloadPage()">{{$t(`message.Restart`)}}</span>
          </div>
        </div>
      </div>
      <hr style="background-color: grey; margin: 0 0 16px 0;" />
      <div>
        <p style="font-size: 16px;font-weight: 700;">Continue in</p>
        <div class="continue-bottom cursor">

          <div class="continue-item cursor"
            @click="goPath('https://pdnob.passfab.com/split-pdf?from_toplist','splitpdf')">
            <img src="../assets/allTotals/splitPDF.svg" alt="">
            <span>{{$t(`message.FunList[1]`)}}</span>
            <img class="rightimg" src="../assets/convert/SuccessRight.svg" alt="">
          </div>
          <div class="continue-item cursor"
            @click="goPath('https://pdnob.passfab.com/delete-pages-from-pdf?from_funlist','deletepages')">
            <img src="../assets/allTotals/DP.svg" alt="">
            <span>{{$t(`message.FunList[7]`)}}</span>
            <img class="rightimg" src="../assets/convert/SuccessRight.svg" alt="">
          </div>
          <div class="continue-item cursor"
            @click="goPath('https://pdnob.passfab.com/password-protect-pdf?from_funlist','protectpdf')">
            <img src="../assets/allTotals/PP.svg" alt="">
            <span>{{$t(`message.FunList[6]`)}}</span>
            <img class="rightimg" src="../assets/convert/SuccessRight.svg" alt="">
          </div>
          <div class="continue-item" @click="goPath('https://pdnob.passfab.com/rotate-pdf?from_funlist','rotatepdf')">
            <!-- $gtag.event('continue_in', {event_category: '$parent.pageName', event_label: 'compresspdf'}) -->
            <img src="../assets/allTotals/RP.svg" alt="">
            <span>{{$t(`message.FunList[5]`)}}</span>
            <img class="rightimg" src="../assets/convert/SuccessRight.svg" alt="">
          </div>
        </div>
      </div>
    </div>

  </el-col>
</template>
<script>
  import { openPop } from "../api/common";
  import PDF from "pdfjs-dist";
  PDF.disableWorker = true;
  export default {
    data() {
      return {
        downUrl: this.$parent.downInof.downUrl,
        downFileType: this.$parent.downInof.downFileType,
        downflag: this.$parent.downInof.downflag,
        downFileName: "", //can not use this.downFileType ,updata this data in mounted
        downloadingFlag: false,
        fileInfo: this.fileList,
        downFileOffName: "",
        downFileOffImg:
          require("../assets/download/word-file.svg"),
        successText: "Your file is ready",
        gaCurrentCat: "",
        pages: 0,
        occupancyFlag: true,
        enterComTime: '',
        isDown: false,
        btnloading: false,
        comName: "pdnob_converted"
      };
    },
    props: ["fileList"],
    methods: {
      checkAppState() {
        return this.$store.state.appState;
      },
      downloadUrlFile(url, fileName) {
        console.log("downloadUrlFile:", url, fileName);
        let gaStartime = new Date().getTime();
        const _this = this;
        const url2 = url.replace(/\\/g, "/");
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url2, true);
        xhr.responseType = "blob";
        //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        xhr.onload = () => {
          if (xhr.status === 200) {
            // 获取文件blob数据并保存
            this.saveAs(xhr.response, fileName);
            this.downloadingFlag = false;
            this.downflag = true;
            let gaTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
            _this.$gtag.event("download_success", {
              event_category: _this.$parent.pageName,
              event_label: `${gaTime}`
            });
          } else {
            // console.log("download err");
            this.downloadingFlag = false;
            // let enterLastTime = Math.floor((new Date().getTime() - _this.enterComTime) / 1000 / 60)
            openPop(_this, 8);
            _this.$gtag.event("download_fail", {
              event_category: _this.$parent.pageName,
              event_label: "nofiles"
            });
          }
        };
        xhr.onerror = (err) => {
          //////console.log("download err");
          console.log("err:", err);

          this.downloadingFlag = false;
          let enterLastTime = Math.floor((new Date().getTime() - _this.enterComTime) / 1000 / 60)
          //////console.log(enterLastTime)
          //////console.log((new Date().getTime() - _this.enterComTime))
          // if (enterLastTime > 60) {

          //   openPop(_this, 8);
          // } else {
          //   openPop(_this, 6);

          // }
          _this.$gtag.event("download_fail", {
            event_category: _this.$parent.pageName,
            event_label: "network_error"
          });
          _this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.UploadFun.message[3]`),
          });
        };
        xhr.send();
      },
      saveAs(data, name) {
        const urlObject = window.URL || window.webkitURL || window;
        const export_blob = new Blob([data]);
        const save_link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = name;
        save_link.click();
      },
      clickDown() {
        this.downloadingFlag = true;
        this.btnloading = true
        // this.downUrl = 'http://' + this.downUrl.split('//')[1]
        this.downloadUrlFile(this.downUrl, this.downFileName);
        // console.log("down:", this.downUrl, this.downFileName);
        // this.downloadUrlFile("https://pdnob-test-api.afirstsoft.cn/static/1661240944ga7/download/56a13faa8cedb75111808b2a53.docx", this.downFileName);
        this.$emit("update:downflag", true);
        this.$store.commit('changeDown', 1)
        let _this = this
        this.$gtag.event("click_download", {
          event_category: _this.$parent.pageName,
          event_label: "null",
        });
      },
      reloadPage() {
        this.downflag;
        const _this = this;
        // //console.log(_this.$route);
        _this.$gtag.event("download_restart", {
          event_category: _this.$parent.pageName,
          event_label: "null",
        });
        if (_this.downflag) {
          window.location.href = _this.$route.fullPath;
        } else {
          _this.$gtag.event("no_download", {
            event_category: _this.$parent.pageName,
            event_label: "null",
          });
          // //console.log("reloadPageElse");
          this.$confirm(this.$t(`message.commonTip.alertTip[6].poptext`), {
            confirmButtonText: this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
            cancelButtonText: this.$t(`message.commonTip.alertTip[6].cancelButtonText`),
            type: "none",
            closeOnClickModal: false
          })
            .then(() => {
              window.location.href = _this.$route.fullPath;
              _this.$gtag.event("no_download_leave", {
                event_category: _this.$parent.pageName,
                event_label: "null",
              });
            })
            .catch(() => {
            });

        }
      },
      loadFile(url) {
        PDF.getDocument(url)
          .then((pdf) => {
            // //console.log("enter loadFile");
            this.pdfDoc = pdf;
            this.pages = this.pdfDoc.numPages;
            this.$nextTick(() => {
              this.renderPage(1);
            });
          })
          .catch(function (err) {
            // //console.log(err);
            return false;
          });
      },
      renderPage(num) {
        // //console.log("renderPage");
        this.pdfDoc.getPage(num).then((page) => {
          // const __this = this;
          let canvas = document.getElementById("canvas" + num);
          let ctx = canvas.getContext("2d");
          let dpr = 1; // 设备像素比
          let bsr =
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1; // 浏览器在渲染canvas之前会用几个像素来来存储画布信息,类似img
          let ratio = dpr / bsr;
          let viewport = page.getViewport(1.5);
          canvas.width = viewport.width * ratio; // 画布大小,默认值是width:300px,height:150px
          canvas.height = viewport.height * ratio;
          canvas.style.width = viewport.width + "px"; // 画布的框架大小
          canvas.style.height = viewport.height + "px";
          let renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          // //console.log("---=======------", renderContext.toDataURL('image/jpg'));
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then(function () {
              //////console.log("Page rendered");
              document.querySelector(".pre-icon").src = document.querySelectorAll("#down-pre-area canvas")[0].toDataURL("image/jpg");
              document.querySelector(".pre-icon-wrap").classList.add('pre-icon-conner')
              // document.querySelector(".right-icon").style.display = 'block'
              document.querySelector(".pre-icon").style.opacity = '1'

            })
            .catch(function (err) {
              // //console.log(err);
            });
        });
      },
      goPath(url, name) {
        let _this = this
        // //console.log("_this.$parent:", _this);

        _this.$gtag.event("continue_in", {
          event_category: _this.$parent.pageName,
          event_label: name,
        });
        if (_this.$store.state.isDown == 2) {
          _this.$gtag.event("no_download", {
            event_category: _this.$parent.pageName,
            event_label: "null",
          });
          _this.$confirm(_this.$t(`message.commonTip.alertTip[6].poptext`), {
            confirmButtonText: _this.$t(`message.commonTip.alertTip[6].confirmButtonText`),
            type: "none",
            closeOnClickModal: false
          })
            .then(() => {
              _this.$gtag.event("no_download_leave", {
                event_category: _this.$parent.pageName,
                event_label: "null",
              });
              window.location.href = url
            })
            .catch(() => {
            });
        } else {
          window.location.href = url
        }
      }
    },
    mounted() {
      const _this = this;
      console.log('down----------:', this);
      console.log('down----------:', this.$parent);
      let funName = this.$store.state.funName;
      if (funName == "split") {
        this.comName = "pdnob_split";
      } else if (funName == "merge") {
        this.comName = "pdnob_merged";
      } else if (funName == "watermarked") {
        this.comName = "pdnob_watermarke";
      } else if (funName == "compress") {
        this.comName = "pdnob_compressed";
      } else if (funName == "removeWater") {
        this.comName = "pdnob_removewatermark";
      } else if (funName == "rotate") {
        this.comName = "pdnob_rotated";
      } else if (funName == "protect") {
        this.comName = "pdnob_protected";
      } else if (funName == "delete") {
        this.comName = "pdnob_deleted";
      }
      // else if (funName == "extract images") {
      //   this.comName = "pdnob_split";
      // } else if (funName == "extract pages") {
      //   this.comName = "pdnob_split";
      // }
      this.downFileName = this.$parent.fileList[0].name;
      this.$store.commit('changeDown', 2)
      // this.downFileName = this.downFileName.substring(0, this.downFileName.lastIndexOf(".")) + "_" + this.$store.state.funName + "." + this.downFileType;
      this.downFileName = this.comName + '_' + this.downFileName.substring(0, this.downFileName.lastIndexOf(".")) + "." + this.downFileType;
      // //console.log("this.downFileType:", this.downFileType, this.$parent.currentFun);
      if (this.downFileType == "doc" || this.downFileType == "docx") {
        this.downFileOffName = "Word";
        this.downFileOffImg =
          require("../assets/download/word-file.svg");
      } else if (this.downFileType == "xls" || this.downFileType == "xlsx") {
        this.downFileOffName = "Excel";
        this.downFileOffImg =
          require("../assets/download/excel-file.svg");
      } else if (this.downFileType == "ppt" || this.downFileType == "pptx") {
        this.downFileOffName = "PPT";
        this.downFileOffImg =
          require("../assets/download/ppt-file.svg");
      } else if (this.downFileType == "txt") {
        this.downFileOffName = "TEXT";
        this.downFileOffImg =
          require("../assets/download/txt-file.svg");
      } else if (this.downFileType == "pdf") {
        this.downFileOffName = "PDF";
        this.downFileOffImg =
          require("../assets/merge/filebg.svg");
      } else if (this.downFileType == "zip" && this.$parent.currentFun == 'jpgToPdf') {
        this.downFileOffName = "PDF";
        this.downFileOffImg =
          require("../assets/download/zip-file.svg");
      } else if (this.downFileType == "zip" && this.$parent.currentFun == 'pdfToHtml') {
        this.downFileOffName = "PDF";
        this.downFileOffImg =
          require("../assets/download/zip-file.svg");
      }
      this.successText = this.$t(`message.successText[0]`);
      if (this.downFileType == "zip") {
        this.downFileOffImg =
          require("../assets/download/zip-file.svg");
      }
      this.gaCurrentCat = this.$parent.currentFun
        .replace(/pdf/i, "PDF")
        .replace("To", " to ")
        .replace("word", "Word")
        .replace(/text/i, "TEXT")
        .replace("ppt", "PPT")
        .replace("excel", "Excel")
        .replace("jpg", "Images")
        .replace("Img", "jpg")
        .replace(/html/i, "HTML");
      // //console.log("_this.downFileOffName.toLowerCase():", _this.downFileOffName.toLowerCase());
      if (_this.downFileOffName.toLowerCase() == "pdf" && _this.$store.state.funName != 'protect') {
        // //console.log(_this.downUrl);
        _this.loadFile(_this.downUrl);
      } else if (_this.downFileOffName.toLowerCase() == "pdf" && _this.$store.state.funName == 'protect') {
        _this.loadFile(_this.$store.state.appFileList[0].fileUrl);
        // this.downFileOffImg
      }
      this.enterComTime = new Date().getTime()
      this.$parent.fileList = []


    },
    beforeRouteLeave() {
      // this.downflag;
      const _this = this;
      // //console.log("---------fileList");

      if (this.downflag) {
        window.location.href = _this.$route.path + "?from_restat";
      } else {
        // //console.log("跑了");
      }
    }
  };
</script>
<style lang="scss" scoped>
  .fileUpload {
    width: 85%;
    height: 100%;
    margin-left: 7.5%;
  }

  .fileUpload-Box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72%;
  }

  .box-right {
    display: flex;
    text-align: left;
    flex-direction: column;
    margin-left: 100px;
    width: 50%;

    h3 {

      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #1D1D1F;
      margin-bottom: 16px
    }

    p {
      width: 80%;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(29, 29, 31, 0.7);
    }
  }

  .box-right-DownButton {
    display: flex;
    margin-top: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    border-radius: 28px;
    /* border: 1px solid #5452F6; */
    background-color: #5452F6;
    color: white;
    width: 260px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

    .loading {
      margin-right: 20px;
    }

    &:hover {
      background: #3E3BFF;
      box-shadow: 0px 10px 16px rgba(84, 82, 246, 0.3);
    }

    &:active {
      background: #3432D6;
      box-shadow: 0px 10px 16px rgba(84, 82, 246, 0.3);
    }


  }

  .box-right-DownButton1 {
    display: flex;
    margin-top: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    border-radius: 28px;
    /* border: 1px solid #5452F6; */
    background-color: #5452F6;
    color: white;
    width: 260px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

  }

  .disabled {
    background: #CCCCD9;
  }

  .cursorWait {
    cursor: wait;
  }

  .box-right-RetryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    border-radius: 28px;
    color: #5452F6;
    font-weight: 700;
    width: 260px;

    span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;

      color: #5452F6;

      &:hover {
        color: #3E3BFF !important;
        text-decoration: underline;
      }

      &:active {
        color: #3432D6 !important;

      }
    }

    img {
      margin-right: 10px;
    }


  }

  .continue-bottom {
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 10px;
    justify-content: space-between;

    .continue-item {
      /* display: flex;
      justify-content: space-around; */
      background: #F5F7FD;
      border-radius: 8px;
      padding: 18px 0;
      width: 22.5%;
      border: 1px solid #fff;

      a {
        /* margin-left: -25px; */
        color: black !important;
        vertical-align: middle;
      }

      :first-child {
        margin: 0 15px 0 15px
      }

      :nth-child(3) {
        float: right;
        margin: 7.5px 15px 0 10px
      }

      &:hover {
        border: 1px solid #5452F6;
        background: #ECECFF;

        .rightimg {
          content: url("../assets/download/activeArrow.svg");
        }
      }

      &:active {
        background: #DDDCFD;

        .rightimg {
          content: url("../assets/download/activeArrow.svg");
        }
      }
    }
  }

  .down-name-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;

    &:hover {
      #file-name-hover {
        display: block !important;
      }
    }

    #file-name {
      color: #001637;
      line-height: 20px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      transform: translateY(2px);
    }

    #file-name-hover {
      width: 120%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      /* display: block; */
      background: #ffffff;
      padding: 2px 6px;
      /* opacity: 0; */
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      transition: all 0.4s;
      box-shadow: 0px 2px 8px rgba(33, 34, 42, 0.1);
      word-break: break-all;
      display: none;
      /* &:hover {
        opacity: 1;
      } */
    }
  }

  #down-pre-area {
    /* border: 2px dashed #eaeaea; */
    box-sizing: border-box;
    width: 239px;
    height: 282px;
    border-radius: 16px;
    background: #F5F7FD;
    border-radius: 16px;
    padding: 16px 0 0 0;
    /* padding: 28px 20px; */
    margin: 32px auto 28px;
  }

  #down-area {
    padding-top: 40px;
    box-shadow: 1px 0px 10px #eaeaea;
    height: 100%;

    h2 {
      font-weight: 700;
      font-size: 20px;
      color: #001637;
    }




    #to-home {
      color: #001637;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.4;
      font-weight: 500;
      margin-top: 12px;

      &:hover {
        cursor: pointer;
      }
    }


    #file-size {
      margin-top: 12px;
      color: #001637;
      font-size: 14px;
      font-weight: 500;

      line-height: 18px;
      opacity: 0.5;
    }
  }

  .success-icon {
    position: relative;
    top: 66px;
    right: 16px;
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  .right-icon {
    position: relative;
    right: 190px;
    height: 212px;
    width: 172px;
  }

  canvas {
    display: none;
  }

  .pre-icon-wrap {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 120px;
    /* height: 200px; */
    margin: 0 auto;

    .pre-icon {
      max-width: 120px;
      min-width: 120px;
      max-height: 170px;
      z-index: 1;
      opacity: 0;
    }

    &.pre-icon-conner {
      /* box-shadow: 0px 6px 11px rgb(33 34 42 / 10%); */
      position: relative;
      /* border-radius: 0 52px 0 0; */
      /* overflow: hidden; */

      /* &::after {

        content: "";
        width: 38px;
        height: 41px;
        background: url("../assets/convert/success.svg") no-repeat left bottom;
        position: absolute;
        top: 0;
        right: 0;
      } */
    }
  }
</style>